// Actions
const MAKE_ORDER = 'order/MAKE-ORDER';

const initialState = {
  order: null,
  loading: 0,
  error: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MAKE_ORDER:
      return {
        ...state,
        loading: 1,
        error: 0
      };
    case MAKE_ORDER + '_SUCCESS':
      return {
        ...state,
        order: action.payload.data.values,
        loading: 0,
        error: 0
      };
    case MAKE_ORDER + '_FAIL':
      return {
        ...state,
        loading: 0,
        error: 1
      };
    default:
      return state;
  }
}

export function makeOrder(token, body) {
  return {
    type: MAKE_ORDER,
    payload: {
      request: {
        method: 'POST',
        url: `/orders/?token=${token}`,
        data: body
      }
    }
  };
}

// user-service.dailybox.id/orders/?token=<user></user>_token
