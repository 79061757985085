// Actions
const GET_PRODUCTS = 'product/GET-PRODUCTS';
const GET_PRODUCTS_BY_CATEGORY_AND_OUTLET = 'product/GET-PRODUCTS-BY-CATEGORY-AND-OUTLET';
const SEARCH_PRODUCT_BY_KEYWORD_AND_OUTLET = 'product/SEARCH-PRODUCT-BY-KEYWORD-AND-OUTLET';
const GET_SIMILAR_PRODUCTS_BY_OUTLET_AND_CATEGORY_AND_PRODUCT = 'product/GET-SIMILAR-PRODUCTS-BY-OUTLET-AND-CATEGORY-AND-PRODUCT';

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case GET_PRODUCTS:
      // Perform action
      return state;
    case GET_PRODUCTS_BY_CATEGORY_AND_OUTLET:
      // Perform action
      return state;
    case SEARCH_PRODUCT_BY_KEYWORD_AND_OUTLET:
      // Perform action
      return state;
    case GET_SIMILAR_PRODUCTS_BY_OUTLET_AND_CATEGORY_AND_PRODUCT:
      // Perform action
      return state;
    default: return state;
  }
}

// Action Creators
export function getProducts() {
  return { type: GET_PRODUCTS };
}

export function getProductsByCategoryAndOutlet() {
  return { type: GET_PRODUCTS_BY_CATEGORY_AND_OUTLET };
}

export function searchProductByKeywordAndOutlet() {
  return { type: SEARCH_PRODUCT_BY_KEYWORD_AND_OUTLET };
}

export function getSimilarProductsByOutletAndCategoryAndProduct() {
  return { type: GET_SIMILAR_PRODUCTS_BY_OUTLET_AND_CATEGORY_AND_PRODUCT };
}
