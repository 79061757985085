import React, { Fragment, Component, lazy, Suspense } from 'react';
import Navbar from 'Layout/Navbar';
import BottomBarCart from 'Partials/BottomBarCart';
import CategoryModal from 'Partials/CategoryModal';
import { Affix } from 'antd';
// partials
import CategorySliderHomeVanilla from 'Partials/CategorySliderHomeVanilla';
import SearchBar from 'Partials/SearchBar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as cartAction from 'Store/modules/cart';
import * as menuAction from 'Store/modules/menu';
import './Homepage.less';

const MenuItem = lazy(() => import('Partials/MenuItem'));

class Category extends Component {
  state = {
    searchFilter: '',
    fromRecommendation: null,
    fromRecomendationLoading: true,
    modalVisible: false,
    modalItem: null,
    modalFullItem: null,
    modalItemOptions: {
      amount: 1
    },
    itemInCart: {}
  };

  componentDidMount = () => {
    if (!this.props.location.state || !this.props.location.state.menuName) {
      this.setState(
        {
          fromRecommendation: false,
          fromRecomendationLoading: false
        },
        () => {
          this.getMenuFilter();
        }
      );
    } else {
      this.setState(
        {
          searchFilter: this.props.location.state.menuName,
          fromRecommendation: true,
          fromRecomendationLoading: false
        },
        () => {
          this.getMenuFilter(this.state.searchFilter);
        }
      );
    }

    let data = {};

    for (let i in this.props.cart.menu) {
      const sumAmount = this.props.cart.menu[i].options.reduce((a, b) => {
        return a + b.amount;
      }, 0);

      if (!data[this.props.cart.menu[i].fullMenuData.id]) {
        data[this.props.cart.menu[i].fullMenuData.id] = sumAmount;
      } else {
        data[this.props.cart.menu[i].fullMenuData.id] += sumAmount;
      }
    }

    this.setState({
      itemInCart: data
    });
  };

  componentDidUpdate = async prevProps => {
    if (prevProps.cart !== this.props.cart) {
      let data = {};
      // TODO: HOT FIX
      for (let i in this.props.cart.menu) {
        if (!data[this.props.cart.menu[i].fullMenuData.id]) {
          let totalFromOptions = 0;
          for (let j in this.props.cart.menu[i].options) {
            totalFromOptions += this.props.cart.menu[i].options[j].amount;
          }
          data[this.props.cart.menu[i].fullMenuData.id] = totalFromOptions;
        } else {
          let totalFromOptions = 0;
          for (let j in this.props.cart.menu[i].options) {
            totalFromOptions += this.props.cart.menu[i].options[j].amount;
          }
          data[this.props.cart.menu[i].fullMenuData.id] += totalFromOptions;
        }
      }

      await this.setState({
        itemInCart: data
      });
    }

    const outletDiff =
      this.props.selected_outlet.id !== prevProps.selected_outlet.id;

    const categoryNameDiff =
      this.props.match.params.category_name !==
      prevProps.match.params.category_name;

    if (outletDiff || categoryNameDiff) {
      this.getMenuFilter();
      return true;
    }
  };

  getMenuFilter = () => {
    this.props.getMenuFilter(
      this.props.selected_outlet.id,
      this.props.match.params.category_name
    );
  };

  searchFilter = (value = '') => {
    this.setState({
      searchFilter: value
    });
  };

  modalHandleOk = async data => {
    let fullData = [...this.props.cart.menu];
    if (!fullData.length) {
      fullData.push({
        id: data.id,
        options: [data.options],
        fullMenuData: data.fullMenuData
      });
    } else {
      let duplicate = false;
      for (let i in fullData) {
        if (fullData[i].id === data.id) {
          // TODO: another check for if the insides of options is duplicate too.
          fullData[i].options.push(data.options);
          duplicate = true;
          break;
        }
      }

      if (!duplicate) {
        fullData.push({
          id: data.id,
          options: [data.options],
          fullMenuData: data.fullMenuData
        });
      }
    }

    await this.props.addToCart(fullData);
    await this.setState({
      modalVisible: false
    });
  };

  modalHandleCancel = () => {
    this.setState({
      modalVisible: false
    });
  };

  openModal = async (item, fullItem) => {
    const itemOptions = {};
    if (item.options) {
      for (let i in item.options) {
        itemOptions[item.options[i].field] = {
          val: item.options[i].value.split(',')[0],
          addPrice: item.options[i].add_price.split(',')[0]
        };
      }

      await this.setState({
        modalItem: item,
        modalFullItem: fullItem,
        modalItemOptions: {
          amount: 1,
          notes: '',
          ...itemOptions
        },
        modalVisible: true
      });
    } else {
      item.options = [];
      this.setState({
        modalItem: item,
        modalFullItem: fullItem,
        modalItemOptions: {
          amount: 1
        },
        modalVisible: true
      });
    }
  };

  clearSearch = () => {
    this.setState({
      searchFilter: ''
    });
  };
  s;

  cartCounter = id => {
    const exist = this.state.itemInCart[id] || null;
    return exist;
  };

  cartCalculator = () => {
    return this.props.cart.menu.reduce((total, current) => {
      return (
        total +
        current.options.reduce((subtotal, option) => {
          return subtotal + option.amount;
        }, 0)
      );
    }, 0);
  };

  render() {
    return (
      <Fragment>
        <Navbar history={this.props.history} />
        {this.state.modalVisible && (
          <CategoryModal
            fullItem={this.state.modalFullItem}
            item={this.state.modalItem}
            itemOptions={this.state.modalItemOptions}
            modalHandleOk={this.modalHandleOk}
            modalHandleCancel={this.modalHandleCancel}
          />
        )}

        <Affix
          offsetTop={0}
          style={{
            zIndex: 999
          }}
        >
          <CategorySliderHomeVanilla
            categoryPath={this.props.match.params.category_name}
            clearSearch={this.clearSearch}
          />
        </Affix>

        {!this.state.fromRecomendationLoading ? (
          <SearchBar
            searchFilter={this.searchFilter}
            searchFilterState={this.state.searchFilter}
            path={this.props.history.location.pathname}
          />
        ) : null}

        {this.props.menu.menu.map(product => {
          if (
            product.menu_name
              .toLowerCase()
              .includes(this.state.searchFilter.toLowerCase())
          ) {
            return (
              <Suspense fallback={<div />} key={product.id}>
                <MenuItem
                  openModal={this.openModal}
                  key={product.id}
                  cartAmount={this.cartCounter(product.id)}
                  fullData={product}
                  fullMenuData={product}
                  menuData={{
                    category_id: product.category_id,
                    id: product.id,
                    name: product.menu_name,
                    description: product.menu_desc,
                    price: product.price,
                    image: product.menuImages[0].image_url_sm,
                    onStock: product.flag_on_stock
                  }}
                  buttonDisable={this.cartCalculator() >= 50 ? true : false}
                />
              </Suspense>
            );
          }
        })}
        {this.props.cart.menu.length ? (
          <Fragment>
            <div style={{ height: 84 }} />
            <BottomBarCart history={this.props.history} />
          </Fragment>
        ) : (
          <div style={{ height: 21 }} />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu,
    selected_outlet: state.outlet.selected_outlet,
    cart: state.cart
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...menuAction, ...cartAction }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Category);
