import React, { Component } from 'react';
import { Button, Carousel, Input, Form, Icon, message } from 'antd';
import './Login.less';
import { Link } from 'react-router-dom';

import NavbarMini from 'Layout/NavbarMini';

import axios from 'axios';

// must have redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as authAction from 'Store/modules/auth';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      current_slide: 0,
      email_checked: false,
      email_exist: false,
      current_error_code: 0
    };
  }
  componentDidMount() {
    this.props.resetAuth();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.login_status === 3) {
      this.props.history.push('/');
    } else if (nextProps.auth.errorCode > 0) {
      if (nextProps.auth.errorCode !== this.state.current_error_code) {
        switch (nextProps.auth.errorCode) {
          case 0.75:
            message.error('The email you submitted is not registered.');
            break;
          case 0.7:
            message.error(
              'Please check your e-mail for verification link before using dailybox, thank you.'
            );
            break;
          case 0.25:
            message.error('Email and password combination does not match.');
            break;
          default:
            message.error('Something went wrong. Please try again.');
            break;
        }
        this.setState({
          current_error_code: nextProps.auth.errorCode
        });
      }
    }
  }
  onChange(a, b) {
    this.setState({
      current_slide: b
    });
  }
  checkForm() {
    if (this.state.current_slide === 0) {
      this.checkEmailForm();
    } else {
      this.checkPasswordForm();
    }
  }
  checkEmailForm() {
    this.props.form.validateFields(['email'], (err, values) => {
      if (!err) {
        this.setState(values);
        this.slider.next();
      } else {
        console.log(err);
      }
    });
  }
  checkPasswordForm() {
    this.props.form.validateFields(['password'], (err, values) => {
      if (!err) {
        this.setState(values);
        let { email } = this.state;
        this.props.login(email, values.password);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login-page">
        <NavbarMini outletSelection={false} />
        <div className="login-page-content">
          <Carousel
            ref={slider => (this.slider = slider)}
            swipe={false}
            infinite={false}
            beforeChange={(from, to) => this.onChange(from, to)}
            dots={false}
          >
            <div className="login-form">
              <Form>
                <p>
                  {' '}
                  Welcome to Dailybox, for now you have to type your email
                  address.{' '}
                </p>
                <Form.Item>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        required: true,
                        type: 'email',
                        message: 'Please input your email!'
                      },
                      {
                        message: 'Email is not registered yet',
                        validator: function(rule, value, callback) {
                          const valueLowercase = value.toLowerCase();
                          if (value.length > 5) {
                            axios
                              .get(
                                'https://user-service.psudocode.now.sh/api/isEmailExist?email=' +
                                  valueLowercase
                              )
                              .then(res => {
                                if (res.data.users.length > 0) {
                                  callback();
                                } else {
                                  callback(true);
                                }
                              });
                          } else {
                            callback();
                          }
                        }
                      }
                    ]
                  })(
                    <Input
                      size="large"
                      prefix={
                        <Icon
                          type="mail"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      style={{
                        textTransform: 'lowercase'
                      }}
                      placeholder="Email"
                    />
                  )}
                </Form.Item>
              </Form>
            </div>
            <div className="login-form">
              <Form>
                <p>Please type your password</p>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [
                      { required: true, message: 'Please input your password!' }
                    ]
                  })(
                    <Input
                      size="large"
                      type="password"
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      style={{
                        textTransform: 'lowercase'
                      }}
                      placeholder="Password"
                    />
                  )}
                  {this.props.auth.login_status === 2 && (
                    <div>Incorrect Password</div>
                  )}
                </Form.Item>
                <Link to="/forgot-password">Forgot password ?</Link>
                <br />
                <br />
              </Form>
            </div>
          </Carousel>

          {this.state.current_slide !== 1 && (
            <div className="login-page-action">
              <Button
                type="primary"
                shape="round"
                onClick={() => this.checkForm()}
              >
                Next
              </Button>
            </div>
          )}
          {this.state.current_slide === 1 && (
            <div className="login-page-action">
              <Button
                type="seccondary"
                shape="round"
                onClick={() => this.slider.prev()}
              >
                Back
              </Button>
              <div style={{ width: 14 }} />
              <Button
                type="primary"
                shape="round"
                onClick={() => this.checkForm()}
              >
                Login
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const LoginwithForm = Form.create({ name: 'normal_login' })(Login);

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...authAction }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginwithForm);
