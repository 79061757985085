import React, { Fragment, Suspense, lazy } from 'react';
import { css } from 'glamor';
const Navbar = lazy(() => import('Layout/Navbar'));
const BottomBar = lazy(() => import('Partials/BottomBar'));
const SVG = lazy(() => import('react-svg'));

export default props => {
  const { innerWidth: width, innerHeight: height } = window;

  const styles = css({
    ' svg': {
      height: width - 14 * 2,
      width: width - 14 * 2
    }
  });

  return (
    <Fragment>
      <Suspense fallback={<div>Loading Header</div>}>
        <Navbar />
      </Suspense>

      <div
        style={{
          overflow: 'hidden',
          padding: 14,
          textAlign: 'center',
          height: height - 140
        }}
      >
        <h2>Inbox is coming soon!</h2>
        <p>Meanwhile, please use Dailybox for ordering purposes.</p>

        <Suspense fallback={<div>Loading coming soon image</div>}>
          <SVG
            {...styles}
            src={require('Assets/Images/Template/coming_soon.svg')}
          />
        </Suspense>
      </div>

      <Suspense fallback={<div>Loading Footer</div>}>
        <BottomBar history={props.history} />
      </Suspense>
    </Fragment>
  );
};
