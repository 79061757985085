import React, { Fragment, lazy, Suspense } from 'react';
import './Login.less';
import { bindActionCreators } from 'redux';
import { Avatar, Divider, Typography } from 'antd';
import { connect } from 'react-redux';
import * as authAction from 'Store/modules/auth';
import './Account.less';

const Navbar = lazy(() => import('Layout/Navbar'));
const BottomBar = lazy(() => import('Partials/BottomBar'));
const { Text, Title } = Typography;

const Account = props => {
  return (
    <Fragment>
      <Suspense fallback={<div>Loading Header</div>}>
        <Navbar />
      </Suspense>
      <div style={{ padding: 20, fontSize: 16 }}>
        <Title level={2}>Terms and Conditions</Title>

        <Title level={3}>Introduction</Title>

        <Text>
          These Website Standard Terms and Conditions written on this webpage
          shall manage your use of our website, Dailybox accessible at
          https://dailybox.id.
        </Text>

        <Text>
          These Terms will be applied fully and affect to your use of this
          Website. By using this Website, you agreed to accept all terms and
          conditions written in here. You must not use this Website if you
          disagree with any of these Website Standard Terms and Conditions.
        </Text>

        <Text>
          Minors or people below 12 years old are not allowed to use this
          Website.
        </Text>

        <Title level={3}>Intellectual Property Rights</Title>

        <Text>
          Other than the content you own, under these Terms, PT. Dailybox and/or
          its licensors own all the intellectual property rights and materials
          contained in this Website.
        </Text>

        <Text>
          You are granted limited license only for purposes of viewing the
          material contained on this Website.
        </Text>

        <Title level={3}>Restrictions</Title>

        <Text>You are specifically restricted from all of the following:</Text>

        <ul>
          <li>publishing any Website material in any other media;</li>
          <li>
            selling, sublicensing and/or otherwise commercializing any Website
            material;
          </li>
          <li>publicly performing and/or showing any Website material;</li>
          <li>
            using this Website in any way that is or may be damaging to this
            Website;
          </li>
          <li>
            using this Website in any way that impacts user access to this
            Website;
          </li>
          <li>
            using this Website contrary to applicable laws and regulations, or
            in any way may cause harm to the Website, or to any person or
            business entity;
          </li>
          <li>
            engaging in any data mining, data harvesting, data extracting or any
            other similar activity in relation to this Website;
          </li>
          <li>using this Website to engage in any advertising or marketing.</li>
        </ul>

        <Text>
          Certain areas of this Website are restricted from being access by you
          and PT. Dailybox may further restrict access by you to any areas of
          this Website, at any time, in absolute discretion. Any user ID and
          password you may have for this Website are confidential and you must
          maintain confidentiality as well.
        </Text>

        <Title level={3}>Your Content</Title>

        <Text>
          In these Website Standard Terms and Conditions, "Your Content" shall
          mean any audio, video text, images or other material you choose to
          display on this Website. By displaying Your Content, you grant PT.
          Dailybox a non-exclusive, worldwide irrevocable, sub licensable
          license to use, reproduce, adapt, publish, translate and distribute it
          in any and all media.
        </Text>

        <Text>
          Your Content must be your own and must not be invading any
          third-party’s rights. PT. Dailybox reserves the right to remove any of
          Your Content from this Website at any time without notice.
        </Text>

        <Title level={3}>No warranties</Title>

        <Text>
          This Website is provided "as is," with all faults, and PT. Dailybox
          express no representations or warranties, of any kind related to this
          Website or the materials contained on this Website. Also, nothing
          contained on this Website shall be interpreted as advising you.
        </Text>

        <Title level={3}>Limitation of liability</Title>

        <Text>
          In no event shall PT. Dailybox, nor any of its officers, directors and
          employees, shall be held liable for anything arising out of or in any
          way connected with your use of this Website whether such liability is
          under contract.  PT. Dailybox, including its officers, directors and
          employees shall not be held liable for any indirect, consequential or
          special liability arising out of or in any way related to your use of
          this Website.
        </Text>

        <Title level={3}>Indemnification</Title>

        <Text>
          You hereby indemnify to the fullest extent PT. Dailybox from and
          against any and/or all liabilities, costs, demands, causes of action,
          damages and expenses arising in any way related to your breach of any
          of the provisions of these Terms.
        </Text>

        <Title level={3}>Severability</Title>

        <Text>
          If any provision of these Terms is found to be invalid under any
          applicable law, such provisions shall be deleted without affecting the
          remaining provisions herein.
        </Text>

        <Title level={3}>Variation of Terms</Title>

        <Text>
          PT. Dailybox is permitted to revise these Terms at any time as it sees
          fit, and by using this Website you are expected to review these Terms
          on a regular basis.
        </Text>

        <Title level={3}>Assignment</Title>

        <Text>
          The PT. Dailybox is allowed to assign, transfer, and subcontract its
          rights and/or obligations under these Terms without any notification.
          However, you are not allowed to assign, transfer, or subcontract any
          of your rights and/or obligations under these Terms.
        </Text>

        <Title level={3}>Entire Agreement</Title>

        <Text>
          These Terms constitute the entire agreement between PT. Dailybox and
          you in relation to your use of this Website, and supersede all prior
          agreements and understandings.
        </Text>

        <Title level={3}>Governing Law & Jurisdiction</Title>

        <Text>
          These Terms will be governed by and interpreted in accordance with the
          laws of the State of id, and you submit to the non-exclusive
          jurisdiction of the state and federal courts located in id for the
          resolution of any disputes.
        </Text>
      </div>
      <Suspense fallback={<div>Loading Header</div>}>
        <BottomBar history={props.history} />
      </Suspense>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...authAction }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
