import React, { Component, Fragment, lazy, Suspense } from 'react';
import { Layout, Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ArticlesHome from 'Partials/ArticlesHome';
import BannerHome from 'Partials/BannerHome';
import * as bannerAction from 'Store/modules/banner';
import * as recommendedAction from 'Store/modules/recommended';
import * as topPicksActions from 'Store/modules/topPicks';
import './Homepage.less';
import ModalOutlet from 'Partials/ModalOutlet';
import DatePicker from 'react-mobile-datepicker';

const Navbar = lazy(() => import('Layout/Navbar'));
const BottomBar = lazy(() => import('Partials/BottomBar'));
const CategorySliderHomeVanilla = lazy(() =>
  import('Partials/CategorySliderHomeVanilla')
);
const TopPicksSlider = lazy(() => import('Partials/TopPicksSlider'));
const RecomendedSliderHome = lazy(() =>
  import('Partials/RecomendedSliderHome')
);
const RecommendedSliderHomeVanilla = lazy(() =>
  import('Partials/RecommendedSliderHomeVanilla')
);
const StoresListSlider = lazy(() => import('Partials/StoresListSlider'));

const { Content } = Layout;

class App extends Component {
  componentDidMount = () => {
    this.props.getBanner(this.props.outlet.selected_outlet.id);
    this.props.getRecommended(this.props.outlet.selected_outlet.id);
    this.props.getTopPicks(this.props.outlet.selected_outlet.id);
  };

  render() {
    return (
      <Fragment>
        <ModalOutlet />
        <Suspense fallback={<div>Loading Header</div>}>
          <Navbar />
        </Suspense>
        <div className="banner">
          <BannerHome />
        </div>
        <Suspense fallback={<div>Loading Slider</div>}>
          <CategorySliderHomeVanilla />
        </Suspense>
        <div className="section-devider-short" />
        <Content>
          <div className="section-title">Recommended</div>
          <Suspense fallback={<div>Loading Slider</div>}>
            <RecommendedSliderHomeVanilla history={this.props.history} />
          </Suspense>
        </Content>
        <div className="section-devider" />
        <Content>
          <div className="section-title">Our Top Picks</div>
          <Suspense fallback={<div></div>}>
            {/* this is my TopPicksSlider */}
            <TopPicksSlider history={this.props.history} />
          </Suspense>
        </Content>
        <div className="section-devider" />
        <Content>
          <div className="section-title">News & Updates</div>
          <ArticlesHome />
        </Content>
        {/* <Content>
          <div className="section-title">Our Stores</div>
          <Suspense fallback={<div />}><StoresListSlider /></Suspense>
        </Content> */}
        <Suspense fallback={<div />}>
          <BottomBar history={this.props.history} />
        </Suspense>
        <div style={{ marginTop: 60 }} />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    banner: state.banner,
    outlet: state.outlet,
    topPicks: state.topPicks
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...bannerAction, ...recommendedAction, ...topPicksActions },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
