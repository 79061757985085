import React, { Fragment, useEffect, useState, lazy, Suspense } from 'react';
import { Typography, Button } from 'antd';

const BottomBar = lazy(() => import('Partials/BottomBar'));
const Navbar = lazy(() => import('Layout/Navbar'));
const { Title, Text } = Typography;

const VerifiedEmail = props => {
  const { innerWidth: width, innerHeight: height } = window;

  const [email, setEmail] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (props.location.search) {
      if (props.location.search.split('=')[1]) {
        setEmail(props.location.search.split('=')[1]);
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  }, []);

  return (
    <div>
      <Suspense fallback={<div>Loading Header</div>}>
        <Navbar />
      </Suspense>

      <div
        style={{
          overflow: 'hidden',
          padding: 14,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          textAlign: 'center',
          height: height - 140,
          marginTop: 24
        }}
      >
        <div>
          <Title level={3}>Thank you!</Title>
          <Text>
            your account with email address {email} has been verified.
          </Text>
        </div>
        <Button
          style={{ marginTop: 36 }}
          type="primary"
          onClick={() => props.history.push('/')}
        >
          Home
        </Button>
      </div>

      <Suspense fallback={<div>Loading Footer</div>}>
        <BottomBar props={props.history} />
      </Suspense>
    </div>
  );
};

export default VerifiedEmail;
