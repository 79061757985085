import React, { Suspense, lazy } from 'react';
import { css } from 'glamor';
const SVG = lazy(() => import('react-svg'));

const OrderEmpty = props => {
  const { innerWidth: width, innerHeight: height } = window;

  const styles = css({
    ' svg': {
      height: width - 14 * 2,
      width: width - 14 * 2
    }
  });

  return (
    <div
      style={{
        overflow: 'hidden',
        padding: 14,
        textAlign: 'center',
        height: height - 140
      }}
    >
      <h2>Your order history is empty 😢</h2>
      <p>When you finish your orders, they will go here.</p>

      <Suspense fallback={<div>Loading coming soon image</div>}>
        <SVG
          {...styles}
          src={require('Assets/Images/Template/empty_box.svg')}
        />
      </Suspense>
    </div>
  );
};

export default OrderEmpty;
