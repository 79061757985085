// Actions
const GET_FAQ = 'faq/GET_FAQ';

const initialState = {
  faq: [],
  loading: 0,
  success: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_FAQ:
      return {
        ...state,
        faq: [],
        loading: 1
      };
    case GET_FAQ + '_SUCCESS':
      return {
        ...state,
        faq: action.payload.data.values,
        loading: 0,
        success: 1
      };
    case GET_FAQ + '_FAIL':
      return {
        ...state,
        faq: [],
        loading: 0,
        success: 0
      };
    default:
      return state;
  }
}

export function getAllFaq() {
  return {
    type: GET_FAQ,
    payload: {
      request: {
        method: 'GET',
        url: '/faqs'
      }
    }
  };
}
