import React, { Fragment, Component } from 'react';
import './MenuItem.less';
import { Row, Col, Divider } from 'antd';
import Skeleton from 'react-loading-skeleton';
import formatPrice from 'Helper/formatPrice';
import Img from 'react-image';
import CartPax from 'Partials/CartPax';
import CartOptions from 'Partials/CartOptions';
import cartTotal from 'Helper/cartTotal';

class CartItem extends Component {
  state = {
    total: 0
  };

  componentDidMount = async () => {
    let [total, _] = await cartTotal([this.props.cartData]);
    this.setState({
      total
    });
  };

  cartClick = () => {
    this.props.openModal(this.props.menuData);
  };

  render() {
    const image = this.props.cartData.fullMenuData.menuImages[0].image_url_sm;
    const name = this.props.cartData.fullMenuData.menu_name;
    const price = formatPrice(this.props.cartData.fullMenuData.price);
    const edible = this.props.editable;

    return (
      <div className="menu-item-parent">
        <Row gutter={16}>
          <Col span={6}>
            <Img
              className="menu-item-image"
              src={`https://assets.dailybox.id/${image}`}
              loader={
                <div style={{ marginBottom: 12 }}>
                  <Skeleton height={72} width={72} duration={0.6} />
                </div>
              }
            />
          </Col>
          <Col span={18}>
            <Row type="flex" justify="space-between" align="top" gutter={16}>
              <Col span={24}>
                <p className="bold-text">{name}</p>
              </Col>
              <Col span={10}>
                <p>{price}/pax</p>
              </Col>
            </Row>
          </Col>

          {this.props.cartData.options.map((eachItem, index) => {
            let keys = Object.keys(eachItem).filter(el => el !== 'amount');
            keys.push('pax');

            return (
              <Fragment key={index}>
                <Divider
                  dashed
                  style={{ margin: '8px 0' }}
                  orientation="right"
                />
                {keys.map(key => {
                  return (
                    <Fragment key={key}>
                      {key === 'pax' ? (
                        <CartPax
                          editable={edible}
                          eachItem={eachItem}
                          key={index}
                          addAmount={this.props.addAmount}
                          removeAmount={this.props.removeAmount}
                          removeOption={this.props.removeOption}
                          editNote={this.props.editNote}
                          itemData={this.props.cartData}
                          optionIndex={index}
                          cartItemIndex={this.props.cartItemIndex}
                        />
                      ) : (
                        <CartOptions
                          editable={edible}
                          eachItem={eachItem}
                          key={key}
                          propsKey={key}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
        </Row>
      </div>
    );
  }
}

export default CartItem;
