/**
 * Cart price formatter **[sm]**
 * @param price the price in number
 * @returns formatted string
 */

export default (price: number): string => {
  return price === 0
    ? 'Free'
    : `+ ${
    Intl.NumberFormat('ID', { style: 'currency', currency: 'IDR' })
      .format(price)
      .split(',')[0]
    }`;
};
