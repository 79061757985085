import React, { Fragment, useEffect } from 'react';
import formatPrice from 'Helper/formatPrice';
import { Row, Col, Divider, Typography } from 'antd';
import Skeleton from 'react-loading-skeleton';
import Img from 'react-image';
import './MenuItem.less';
import CartPax from 'Partials/CartPax';
import CartOptions from 'Partials/CartOptions';
import OrderDetailOptions from 'Partials/OrderDetailOptions';

const { Text, Title } = Typography;

const OrderDetailItem = props => {
  const image = props.item.menu.menuImages[0].image_url_sm;
  const name = props.item.menu.menu_name;
  const price = formatPrice(props.item.menu.price);
  const options = props.item.options;

  return (
    <div>
      <div style={{ backgroundColor: '#F6F6F6', width: '100%', height: 14 }} />
      <div className="menu-item-parent">
        <Row gutter={16}>
          <Col span={6}>
            <Img
              className="menu-item-image"
              src={`https://assets.dailybox.id/${image}`}
              loader={
                <div style={{ marginBottom: 12 }}>
                  <Skeleton height={72} width={72} duration={0.6} />
                </div>
              }
            />
          </Col>
          <Col span={18}>
            <Row type="flex" justify="space-between" align="top" gutter={16}>
              <Col span={24}>
                <p className="bold-text">{name}</p>
              </Col>
              <Col span={10}>
                <p>{price}/pax</p>
              </Col>
            </Row>
          </Col>

          {props.item.options.map((eachOption, index) => {
            return (
              <OrderDetailOptions
                price={props.item.menu.price}
                option={eachOption}
                key={index}
              />
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default OrderDetailItem;
