import React, { Component } from 'react';
import { Layout, Modal } from 'antd';
import './App.less';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUtensilsAlt,
  faUtensils,
  faUserAlt,
  faMailbox,
  faShoppingBasket,
  faListUl,
  faHomeAlt
} from '@fortawesome/pro-regular-svg-icons';

import Login from 'Pages/Login';
import Register from 'Pages/Register';
import Homepage from 'Pages/Homepage';
import Checkout from 'Pages/Checkout';
import Category from 'Pages/Category';
import Auth from 'Pages/Auth';
import Account from 'Pages/Account';
import Cart from 'Pages/Cart';
import PrivateRoute from './PrivateRoute';
import Order from 'Pages/Order';
import OrderDetail from 'Pages/OrderDetail';
import Inbox from 'Pages/Inbox';
import TNC from 'Pages/TNC';
import FAQ from 'Pages/FAQ';
import Contact from 'Pages/Contact';
import ThankYou from 'Pages/ThankYou';
import ThankYouOffline from 'Pages/ThankYouOffline';
import ThankYouRegister from 'Pages/ThankYouRegister';
import ForgotPassword from 'Pages/ForgotPassword';
import ForgotPasswordSet from 'Pages/ForgotPasswordSet';
import VerifiedEmail from 'Pages/VerifiedEmail';
import GooglePhone from 'Pages/GooglePhone';
import EspayPostPayment from 'Pages/EspayPostPayment';
import Espay from 'Pages/Espay';
import EspayRepay from 'Pages/EspayRepay';

import configureStore, { history } from 'Store/configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

const { store, persistor } = configureStore();

class App extends Component {
  componentWillMount = () => {
    library.add(
      faUtensilsAlt,
      faUtensils,
      faUserAlt,
      faMailbox,
      faShoppingBasket,
      faListUl,
      faHomeAlt
    );
  };

  render() {
    return (
      <div id="app-parent">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router
              history={history}
              onUpdate={() => {
                window.scrollTo(0, 0);
                Modal.destroyAll();
              }}
            >
              <LastLocationProvider>
                <Layout className="layout">
                  <Route path="/" exact component={Homepage} />
                  <Route
                    path="/categories/:category_name"
                    exact
                    component={Category}
                  />
                  <Route path="/auth" exact component={Auth} />
                  <Route path="/login" exact component={Login} />
                  <Route path="/cart" component={Cart} />
                  <Route path="/register" component={Register} />
                  <Route
                    path="/thank-you-register"
                    component={ThankYouRegister}
                  />
                  <Route
                    path="/change-password/:uniqueKey"
                    component={ForgotPasswordSet}
                  />
                  <Route path="/espay-gateway" component={Espay} />
                  <Route path="/espay-repay-gateway" component={EspayRepay} />
                  <Route
                    path="espay-post-payment"
                    component={EspayPostPayment}
                  />
                  <Route path="/google-register" component={GooglePhone} />
                  <Route
                    path="/check-email/verified"
                    component={VerifiedEmail}
                  />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <PrivateRoute path="/tnc" component={TNC} />
                  <PrivateRoute path="/faq" component={FAQ} />
                  <PrivateRoute path="/thank-you" component={ThankYou} />
                  <PrivateRoute
                    path="/thank-you-offline"
                    component={ThankYouOffline}
                  />
                  <PrivateRoute path="/contact" component={Contact} />
                  <PrivateRoute path="/order" component={Order} />
                  <PrivateRoute
                    path="/order-detail/:orderNumber"
                    component={OrderDetail}
                  />
                  <PrivateRoute path="/inbox" component={Inbox} />
                  <PrivateRoute path="/checkout" component={Checkout} />
                  <PrivateRoute path="/account" component={Account} />
                </Layout>
              </LastLocationProvider>
            </Router>
          </PersistGate>
        </Provider>
      </div>
    );
  }
}

export default App;
