import React, { useState, useEffect } from 'react';
import { Divider, Row, Col, Typography } from 'antd';
import cartFormatPrice from 'Helper/cartFormatPrice';
import formatPrice from 'Helper/formatPrice';

const OrderDetailOptions = props => {
  const orderTotal = () => {
    const basePrice = Number(props.price);
    let amount = 0;
    let allAddition = 0;
    for (let i in props.option) {
      if (props.option[i].amount) {
        amount = Number(props.option[i].amount);
      } else {
        allAddition += Number(props.option[i].price);
      }
    }

    return (basePrice + allAddition) * amount;
  };

  return (
    <div>
      <Divider dashed style={{ margin: '8px 0' }} orientation="right" />
      {props.option.map((eachOption, index) => {
        if (!eachOption.amount) {
          return (
            <div key={index}>
              <Col span={16}>
                <p>
                  <i style={{ color: '#CECECE' }}>{eachOption.field}</i>
                  <br />
                  {eachOption.value}
                </p>
              </Col>
              <Col
                span={8}
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end'
                }}
              >
                <p
                  style={{
                    color: '#CECECE'
                  }}
                >
                  <i />
                  <br />
                  {cartFormatPrice(eachOption.price)}
                </p>
              </Col>
            </div>
          );
        } else {
          return (
            <div key={index}>
              <Col span={16}>
                <p>{eachOption.amount} pax</p>
              </Col>
              <Col
                span={8}
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end'
                }}
              >
                <b>
                  <p>{formatPrice(orderTotal())}</p>
                </b>
              </Col>
            </div>
          );
        }
      })}
    </div>
  );
};

export default OrderDetailOptions;
