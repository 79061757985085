import React, { Fragment } from 'react';
import { Col } from 'antd';
import cartFormatPrice from 'Helper/cartFormatPrice';

const CartOptions: React.FC = (props: any): any => {
  return (
    <Fragment>
      {props.propsKey === 'notes' ? null : (
        <Fragment>
          <Col span={16}>
            <p>
              <i style={{ color: '#CECECE' }}>{props.propsKey}</i>
              <br />
              {props.eachItem[props.propsKey].val}
            </p>
          </Col>
          <Col
            span={8}
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end'
            }}
          >
            <p
              style={{
                color: '#CECECE'
              }}
            >
              <i />
              <br />
              {cartFormatPrice(props.eachItem[props.propsKey].addPrice)}
            </p>
          </Col>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CartOptions;
