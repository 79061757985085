import React, { Fragment, useState, useEffect, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as orderHistoryAction from 'Store/modules/orderHistory';
import { Divider, Tag } from 'antd';
import './Order.less';
import OrderEmpty from 'Partials/OrderEmpty';
import OrderHistoryListItem from 'Partials/OrderHistoryListItem';

const Navbar = lazy(() => import('Layout/Navbar'));
const BottomBar = lazy(() => import('Partials/BottomBar'));

const Order = props => {
  useEffect(() => {
    const { token } = props.auth.me;
    const { userId } = props.auth.me.user;
    props.getAllOrders(token, userId);
  }, []);

  useEffect(() => {
    console.log('orderHistory is changing!');
    console.log(props.orderHistory);
  }, [props.orderHistory]);

  const openOrderDetail = item => {
    props.history.push({
      pathname: `order-detail/${item.order_number}`
    });
  };

  return (
    <Fragment>
      <Suspense fallback={<div>Loading Header</div>}>
        <Navbar />
        <div style={{ paddingBottom: '14px' }} />
      </Suspense>
      {props.orderHistory.orderHistory.length ? (
        props.orderHistory.orderHistory.map((item, index) => {
          return (
            <Fragment key={index}>
              <OrderHistoryListItem
                item={item}
                index={index}
                openOrderDetail={openOrderDetail}
              />
              <Divider />
            </Fragment>
          );
        })
      ) : (
        <OrderEmpty />
      )}
      <div style={{ marginTop: 60 }} />
      <Suspense fallback={<div>Loading Footer</div>}>
        <BottomBar history={props.history} />
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    orderHistory: state.orderHistory
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...orderHistoryAction }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Order);
