import React, { Component, Fragment } from 'react';
import './MenuItem.less';
import { debounce } from 'lodash';

// must have redux
import { Row, Col, Icon, Input, Button } from 'antd';
import { connect } from 'react-redux';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchOpen: true,
      searchValue: '',
      fromRecommendation: false
    };
  }

  onChangeDebounce = debounce(event => {
    this.onChange(event);
  }, 500);

  componentDidMount = async () => {
    if (this.props.searchFilterState) {
      await this.setState({
        searchValue: this.props.searchFilterState,
        fromRecommendation: true
      });
      this.props.searchFilter(this.state.searchValue);
    }
  };

  componentDidUpdate = prevProps => {
    if (this.props.path !== prevProps.path) {
      this.setState({
        searchOpen: false,
        searchValue: ''
      });
    }
  };

  componentWillUnmount = () => {
    this.onChangeDebounce.cancel();
  };

  onChange = async event => {
    await this.setState({
      searchValue: event.target.value
    });
    this.props.searchFilter(this.state.searchValue);
  };

  onChangeFromRecommendation = async event => {
    await this.setState({
      searchValue: event.target.value,
      fromRecommendation: false
    });
    this.props.searchFilter(this.state.searchValue);
  };

  searchClick = async () => {
    await this.setState({
      searchOpen: !this.state.searchOpen,
      searchValue: ''
    });

    this.props.searchFilter(this.state.searchValue);
  };

  render() {
    return (
      <div className="menu-item-parent">
        <Row>
          {this.state.searchOpen ? (
            <Fragment>
              <Col span={22}>
                <Input
                  placeholder="Find your meal"
                  prefix={<Icon type="search" />}
                  defaultValue={this.props.searchFilterState}
                  onChange={event => {
                    // if (!this.state.fromRecommendation) {
                    //   event.persist();
                    //   this.onChangeDebounce(event);
                    // } else {
                    //   this.onChangeFromRecommendation(event);
                    // }
                    event.persist();
                    this.onChangeDebounce(event);
                  }}
                />
              </Col>
              <Col span={2}>
                <Button
                  type="link"
                  shape="circle"
                  icon="close"
                  onClick={this.searchClick}
                />
              </Col>
            </Fragment>
          ) : (
            <Button shape="circle" icon="search" onClick={this.searchClick} />
          )}
        </Row>
      </div>
    );
  }
}

export default connect()(SearchBar);
