import React, { useEffect, useState } from 'react';

const EspayPostPayment = props => {
  useEffect(() => {
    if (!props.location.state) {
      props.history.push('/');
    } else if (!props.location.state.orderId) {
      props.history.push('/');
    } else {
      console.log(props.location.state.orderId);
      // TODO:
      // Check transaction from backend
      // if orderId is flag = false, send to fail transaction
      // if orderId is flag = true, send to thank you page
    }
  }, []);

  return (
    <div>
      <p>Loading</p>
      {/* Loader */}
    </div>
  );
};

export default EspayPostPayment;
