import React, { Component } from 'react';
import { Icon } from 'antd';
import './NavbarMini.less';
import { withRouter } from 'react-router-dom';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  render() {
    return (
      <div className="navbar-mini">
        <div
          className="navbar-mini-icon-left"
          onClick={() => this.props.history.goBack()}
        >
          <Icon type="left" />
        </div>
        <div className="navbar-mini-title">Dailybox</div>
      </div>
    );
  }
}

export default withRouter(Navbar);
