/**
 * Cart total **[sm]**
 */

export default async (cart: any): Promise<[number, number]> => {
  let totalPrice = 0;
  let totalAmount = 0;

  if (!cart.length) {
    return [0, 0];
  }

  for (let i in cart) {
    // this is each menu item
    let price = cart[i].fullMenuData.price;

    // looping the options
    for (let j in cart[i].options) {
      let amount = cart[i].options[j].amount;
      totalAmount += amount;
      let optionKey = Object.keys(cart[i].options[j]);

      // looping option's keys
      let allAddPrices = 0;
      for (let k in optionKey) {
        let amountOrNotes =
          optionKey[k] !== 'amount' && optionKey[k] !== 'notes';
        if (amountOrNotes) {
          allAddPrices += Number(cart[i].options[j][optionKey[k]].addPrice);
        }
      }

      totalPrice += (price + allAddPrices) * amount;
    }
  }
  return [totalPrice, totalAmount];
};
