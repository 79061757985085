import React, { Fragment, useState, useEffect, Suspense, lazy } from 'react';
import { Tag, Typography } from 'antd';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import OrderDetailItem from 'Partials/OrderDetailItem';
import * as orderHistoryAction from 'Store/modules/orderHistory';
import orderToOrderHistoryFormat from 'Helper/orderToOrderHistoryFormat';
import orderHistoryTotal from 'Helper/orderHistoryTotal';
import formatPrice from 'Helper/formatPrice';
import { connect } from 'react-redux';
import { Card, Button } from 'antd';
import Midtrans from 'Helper/midtrans';

const Navbar = lazy(() => import('Layout/Navbar'));
const BottomBar = lazy(() => import('Partials/BottomBar'));
const { Text, Title } = Typography;

const OrderDetail = props => {
  const [orderNumber, setOrderNumber] = useState(null);
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);
  const [targetOrder, setTargetOrder] = useState(null);
  const [formatOrder, setFormatOrder] = useState(null);
  const [total, setTotal] = useState(0);
  const [finishedLoad, setFinishedLoad] = useState(false);

  useEffect(() => {
    setOrderNumber(props.match.params.orderNumber);
  }, []);

  useEffect(() => {
    if (orderNumber) {
      const { token } = props.auth.me;
      const { userId } = props.auth.me.user;
      props.getAllOrders(token, userId);
      setFirstTimeLoad(false);
    }
  }, [orderNumber]);

  useEffect(() => {
    if (
      !props.orderHistory.loading &&
      !props.orderHistory.error &&
      !firstTimeLoad
    ) {
      const { orderHistory } = props.orderHistory;
      for (let i in orderHistory) {
        if (orderHistory[i].order_number == orderNumber) {
          setTargetOrder(orderHistory[i]);
          setFormatOrder(orderToOrderHistoryFormat(orderHistory[i]));
          break;
        }
      }
      setFinishedLoad(true);
    }
  }, [props.orderHistory]);

  useEffect(() => {
    if (formatOrder) {
      setTotal(orderHistoryTotal(formatOrder));
    }
  }, [formatOrder]);

  const repay = () => {
    const paymentMethod = targetOrder.payment_channel_code;

    if (paymentMethod == '8') {
      props.history.push({
        pathname: '/espay-repay-gateway',
        state: {
          order_number: targetOrder.order_number
        }
      });
    } else if (paymentMethod == '5') {
      repayMidtrans();
    }
  };

  const repayMidtrans = () => {
    const { history } = props;
    const token = props.auth.me.token;
    const { order_number, snap_token } = targetOrder;
    Midtrans.pay(snap_token, order_number, token, history);
  };

  return (
    <Fragment>
      {finishedLoad ? (
        <Fragment>
          <Suspense fallback={<div />}>
            <Navbar />
            <div style={{ paddingBottom: '14px' }} />
          </Suspense>

          {!targetOrder.flag_paid ? (
            <Card
              style={{
                margin: 14,
                marginTop: 0,
                backgroundColor: '#f28e1f',
                borderRadius: 6
              }}
            >
              <div>
                <Title level={4} style={{ color: 'white' }}>
                  Pending Payment
                </Title>
                <Text style={{ color: 'white' }}>
                  This payment has not succeeded. Continue below to avoid
                  cancellation in 1x24 hours.
                </Text>
                <br />
                <div style={{ marginTop: 14 }} />
                <Button onClick={repay} style={{ color: '#f28e1f' }}>
                  Continue Payment
                </Button>
              </div>
            </Card>
          ) : null}

          <div style={{ margin: 14, marginTop: 8 }}>
            <Title level={3}>ORDER DETAIL</Title>

            <div style={{ marginBottom: 14 }}>
              {targetOrder.orderStatus.order_status_name == 'Completed' ? (
                <Tag color="orange">
                  {targetOrder.orderStatus.order_status_name}
                </Tag>
              ) : (
                <Tag>{targetOrder.orderStatus.order_status_name}</Tag>
              )}

              {!targetOrder.flag_paid ? (
                <Tag>Unpaid</Tag>
              ) : (
                <Tag color="orange">Paid</Tag>
              )}
            </div>

            <div style={{ marginBottom: 4 }}>
              <Text>
                {moment(targetOrder.date_updated).format('MM/DD/YYYY, hh:mm a')}
              </Text>
            </div>

            <div style={{ marginBottom: 4 }}>
              <Text>
                Invoice <b>#{targetOrder.order_number}</b>
              </Text>
            </div>

            <div style={{ marginBottom: 4 }}>
              <Text>
                Paid with <b>{targetOrder.payment_channel_name}</b>
              </Text>
            </div>

            <div style={{ marginBottom: 4 }}>
              {/* Hitung total diisni */}
              <Text>
                Total <b>{formatPrice(total)}</b>
              </Text>
            </div>
          </div>

          {formatOrder && formatOrder.length
            ? formatOrder.map((item, index) => (
                <OrderDetailItem item={item} key={index} />
              ))
            : null}

          <div style={{ marginBottom: 60 }} />

          <Suspense fallback={<div />}>
            <BottomBar history={props.history} />
          </Suspense>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

const stateProps = state => {
  return {
    orderHistory: state.orderHistory,
    auth: state.auth
  };
};

const dispatchProps = dispatch => {
  return bindActionCreators({ ...orderHistoryAction }, dispatch);
};

export default connect(
  stateProps,
  dispatchProps
)(OrderDetail);
