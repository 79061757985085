import React, { Component, Fragment } from 'react';
import { Affix, Badge } from 'antd';
import cartTotal from 'Helper/cartTotal';
import { connect } from 'react-redux';
import formatPrice from 'Helper/formatPrice';

import './BottomBarCart.less';

class BottomBarCart extends Component {
  state = {
    totalItems: 0,
    totalPrice: 0
  };

  componentDidMount = async () => {
    const [totalPrice, totalAmount] = await cartTotal(this.props.cart.menu);
    if (this.state.totalPrice !== totalPrice) {
      this.setState({
        totalPrice,
        totalItems: totalAmount
      });
    }
  };

  componentDidUpdate = () => {
    this.setTotal();
  };

  setTotal = async () => {
    const [totalPrice, totalAmount] = await cartTotal(this.props.cart.menu);
    if (this.state.totalPrice !== totalPrice) {
      this.setState({
        totalPrice,
        totalItems: totalAmount
      });
    }
  };

  toCart = () => {
    this.props.history.push('/cart');
  };

  render() {
    return (
      <Fragment>
        <div className="bottom-bar-cart">
          {/*<Affix offsetBottom={0} className="bottom-bar-cart">*/}
          <div
            className="menu-cart-parent"
            style={{
              position: 'fixed',
              bottom: 0
            }}
          >
            <div className="menu-cart" onClick={this.toCart}>
              <Badge count={this.state.totalItems} showZero>
                <div className="item-cart">
                  <p>VIEW CART</p>
                </div>
              </Badge>
              <div className="item-cart">
                <p>{formatPrice(this.state.totalPrice)}</p>
              </div>
            </div>
          </div>
          {/*</Affix>*/}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart
  };
}

export default connect(mapStateToProps)(BottomBarCart);
