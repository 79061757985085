/**
 * Total price for order history **[sm]**
 * @param data 
 * @returns number in total of said order history
 */

export default (data: any): number => {
  let allTotal = 0;
  for (let i in data) {
    const eachProduct = data[i];
    const price = eachProduct.menu_price;
    let total = 0;

    for (let j in eachProduct.options) {
      const eachSubProduct = eachProduct.options[j];
      let optionAmount = 0;
      let addedPrice = 0;

      for (let k in eachSubProduct) {
        const eachOption = eachSubProduct[k];
        if (eachOption.amount) {
          optionAmount = eachOption.amount;
        } else {
          addedPrice += Number(eachOption.price);
        }
      }

      total += (price + addedPrice) * optionAmount;
    }

    allTotal += total;
  }
  return allTotal;
};
