// Actions
const GET_BY_OUTLET = 'recommended/GET_BY_OUTLET';

const initialState = {
  recommended: [],
  recommendedLoading: false,
  recommendedError: false
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_BY_OUTLET:
      return {
        ...state,
        recommendedLoading: true,
        recommendedError: false
      };
    case GET_BY_OUTLET + '_SUCCESS':
      return {
        ...state,
        recommendedLoading: false,
        recommendedError: false,
        recommended: action.payload.data.values
      };
    case GET_BY_OUTLET + '_FAIL':
      return {
        ...state,
        recommendedLoading: false,
        recommendedError: true
      };
    default:
      return state;
  }
}

export function getRecommended(outletId) {
  return {
    type: GET_BY_OUTLET,
    payload: {
      request: {
        method: 'GET',
        // url: `/products/?category_id=5b1e26eccd9cfa2fb82e5603`,
        url: `products/recomended/?outlet_id=${outletId}`
      }
    }
  };
}
