const GET_BANNER = 'banner/GET_BANNER';

const initialState = {
  bannerLoading: false,
  bannerError: false,
  banner: []
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_BANNER:
      return {
        ...state,
        bannerLoading: true,
        bannerError: false
      };
    case GET_BANNER + '_SUCCESS':
      return {
        ...state,
        bannerLoading: false,
        bannerError: false,
        banner: action.payload.data.values
      };
    case GET_BANNER + '_FAIL':
      return {
        ...state,
        bannerError: true,
        bannerLoading: false
      };
    default:
      return state;
  }
}

// Action Creators
export function getBanner(outlet_id) {
  return {
    type: GET_BANNER,
    payload: {
      request: {
        method: 'GET',
        url: `/slide-shows/?${outlet_id}`
      }
    }
  };
}
