import React, { FunctionComponent, Component, ReactType } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

interface PrivateRouteProps {
  component: ReactType;
  path: string;
  auth: any;
}

/**
 * Component middleware for auth checking. **[sm]**
 */

const PrivateRoute = ({
  component: Component,
  path,
  auth
}: PrivateRouteProps) => (
  <Route
    path={path}
    render={props => {
      return auth.login_status === 3 ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/auth' }} />
      );
    }}
  />
);

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(PrivateRoute);
