// Actions
const LOGIN = 'auth/LOGIN';
const REGISTER = 'auth/REGISTER';
const CHECK_EMAIL = 'auth/CHECK-EMAIL';
const RESET_AUTH = 'auth/RESET-AUTH';
const REFETCH_AUTH = 'auth/REFETCH-AUTH';

const initialState = {
  email_exist: false,
  email_exist_loading: false,
  email_exist_error: false,
  registerLoading: false,
  registerError: false,
  errorCode: 0,
  registerSuccess: false,
  registerSuccessData: null,
  registerErrorData: null,
  registerFirstTime: false,
  refetchUserDataLoading: false,
  refetchUserDataError: false
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_AUTH:
      return {
        ...initialState
      };
    case CHECK_EMAIL:
      return {
        ...state,
        email_exist_loading: true,
        email_exist_error: false
      };
    case CHECK_EMAIL + '_SUCCESS':
      return {
        ...state,
        email_exist_loading: false,
        email_exist_error: false,
        email_exist: action.payload.data.users.length > 0
      };
    case CHECK_EMAIL + '_FAIL':
      return {
        ...state,
        email_exist_loading: false,
        email_exist_error: true
      };
    case LOGIN:
      return {
        ...state,
        login_status: 1
      };
    case LOGIN + '_SUCCESS':
      const err = action.payload.data.values.error > 0;
      return {
        ...state,
        errorCode: err ? action.payload.data.values.error : 0,
        me: err ? {} : action.payload.data.values,
        login_status: err ? 2 : 3
      };
    case LOGIN + '_FAIL':
      return state;
    case REGISTER:
      return {
        ...state,
        registerLoading: true,
        registerError: false,
        registerSuccess: false
      };
    case REGISTER + '_SUCCESS':
      const errRegister = action.payload.data.values.error > 0;
      const errCode = action.payload.data.values.error;
      const resRegister = action.payload.data.values.response;
      const resUserCount = action.payload.data.values.count > 0;

      console.log('REGISTER DATA');
      console.log(action.payload.data.values);

      if (resRegister.split(' ')[0] == 'LOGIN' && !errRegister) {
        return {
          ...state,
          registerLoading: false,
          registerError: false,
          registerSuccess: true,
          registerSuccessData: action.payload.data.values,
          errorCode: errRegister ? action.payload.data.values.error : 0,
          me: errRegister ? {} : action.payload.data.values,
          login_status: errRegister ? 2 : 3,
          registerFirstTime: false
        };
      } else if (errRegister) {
        const conditionForRegisterError = errCode == 0.5 ? false : true;

        return {
          ...state,
          registerLoading: false,
          registerError: conditionForRegisterError,
          registerSuccess: false,
          registerSuccessData: action.payload.data.values,
          errorCode: errRegister ? action.payload.data.values.error : 0,
          me: errRegister ? {} : action.payload.data.values,
          login_status: errRegister ? 2 : 3,
          registerFirstTime: !conditionForRegisterError
        };
      } else if (!resUserCount) {
        if (action.payload.data.values.user.register_origin_id == 1) {
          return {
            ...state,
            registerLoading: false,
            registerError: false,
            registerSuccess: true,
            registerSuccessData: action.payload.data.values,
            errorCode: errRegister ? action.payload.data.values.error : 0,
            me: {},
            login_status: 0,
            registerFirstTime: true
          };
        } else {
          console.log('AUTH DARI GOOGLE BARUSAN MASUK SINI BOS');
          if (action.payload.data.values.token) {
            return {
              ...state,
              registerLoading: false,
              registerError: false,
              registerSuccess: true,
              registerSuccessData: action.payload.data.values,
              errorCode: errRegister ? action.payload.data.values.error : 0,
              me: errRegister ? {} : action.payload.data.values,
              login_status: errRegister ? 2 : 3,
              registerFirstTime: false
            };
          } else {
            return {
              ...state,
              registerLoading: false,
              registerError: false,
              registerSuccess: true,
              registerSuccessData: action.payload.data.values,
              errorCode: errRegister ? action.payload.data.values.error : 0,
              me: errRegister ? {} : action.payload.data.values,
              login_status: errRegister ? 2 : 3,
              registerFirstTime: true
            };
          }
        }
      } else {
        return {
          ...state,
          registerLoading: false,
          registerError: false,
          registerSuccess: true,
          registerSuccessData: action.payload.data.values
        };
      }
    case REGISTER + '_ERROR':
      return {
        ...state,
        registerLoading: false,
        registerError: true,
        registerSuccess: false,
        registerErrorData: action.payload.data.values
      };
    // Refetch Auth
    case REFETCH_AUTH:
      return {
        ...state,
        refetchUserDataLoading: true,
        refetchUserDataError: false
      };
    case REFETCH_AUTH + '_SUCCESS':
      return {
        ...state,
        refetchUserDataLoading: false,
        refetchUserDataError: false,
        me: {
          ...state.me,
          db_point: action.payload.data.values.db_point
        }
      };
    case REFETCH_AUTH + '_ERROR':
      return {
        ...state,
        refetchUserDataLoading: false,
        refetchUserDataError: true
      };

    default:
      return state;
  }
}

// Action Creators
export function login(email, password) {
  return {
    type: LOGIN,
    payload: {
      request: {
        method: 'POST',
        url: '/auth/login',
        data: {
          email: email,
          password: password
        }
      }
    }
  };
}

// Action Creators
export function checkEmail(email) {
  return {
    type: CHECK_EMAIL,
    payload: {
      request: {
        method: 'GET',
        url:
          'https://user-service.psudocode.now.sh/api/isEmailExist?email=' +
          email
      }
    }
  };
}

// https://user.psudocode.now.sh/api/isEmailExist?email=didi@krestech.id

export function register(data) {
  console.log('Right before register > ', data);
  return {
    type: REGISTER,
    payload: {
      request: {
        method: 'POST',
        url: '/auth/register',
        data
      }
    }
  };
}
export function resetAuth() {
  return { type: RESET_AUTH };
}

// Refetch
export function refetchAuth(userId, token) {
  return {
    type: REFETCH_AUTH,
    payload: {
      request: {
        method: 'GET',
        url: `/users/${userId}/?token=${token}`
      }
    }
  };
}
