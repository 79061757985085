interface CartCheckoutProps {
  menu: any;
}

/**
 * Cart to checkout formatter (to send over server API) **[sm]**
 * @param menu array of menus from cart
 * @returns object of menuId
 *
 */

export default ({ menu }: CartCheckoutProps) => {
  const formattedCart = [];
  // Looping per product
  for (let i in menu) {
    // Looping per options
    for (let j in menu[i].options) {
      const options = [];
      for (let k in menu[i].options[j]) {
        if (k !== 'amount' && k !== 'notes') {
          options.push({
            field: k,
            value: menu[i].options[j][k].val,
            price: menu[i].options[j][k].addPrice.toString()
          });
        }
      }

      formattedCart.push({
        menu_id: menu[i].id,
        note: `${menu[i].options[j].notes}`,
        options,
        qty_ordered: menu[i].options[j].amount
      });
    }
  }
  return formattedCart;
};
