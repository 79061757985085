import cartTotal from 'Helper/cartTotal';
import cartCheckoutFormat from 'Helper/cartCheckoutFormat';

interface OrderFormatInterface {
  user_id: string;
  payment_method_id: string | number | null;
  total_product_count: number;
  total_item_count: any;
  flag_paid: boolean;
  total_order: any;
  total_order_point: number;
  grand_total: number | any;
  grand_total_rounded: number | any;
  grand_total_point: number;
  grand_total_rounded_point: number;
  total_discount: number;
  total_discount_point: number;
  total_tax: number;
  total_tax_point: number;
  service_type: string;
  payment_channel_code: string | number;
  payment_channel_name: string | number;
  flag_dine_in: boolean;
  pickup_type: string | number;
  outlet_id: string;
  cart: any;
  delivery_address_id: string;
  delivery_time_id: string;
  delivery_fee: number;
  delivery_fee_point: number;
  order_note: string | undefined | null
}

/**
 * Format for ordering items **[sm]**
 * @param data checkout items to make order into
 * @returns orderFormat
 */

export default async (data: any): Promise<OrderFormatInterface> => {
  const { cart, auth, paymentMethod, pickupTime, outlet } = data;

  const formattedCart = cartCheckoutFormat(cart);

  let payment_method_id = null;
  if (paymentMethod.pg_code == '0') {
    payment_method_id = '2';
  } else if (paymentMethod.pg_code == '8') {
    payment_method_id = '8';
  } else if (paymentMethod.pg_code == '5') {
    payment_method_id = '7';
  }

  const pickup_type =
    pickupTime === 'Pre-order I' ? 1 : pickupTime === 'Pre-order II' ? 2 : 0;

  const [totalPrice, totalPax] = await cartTotal(cart.menu);

  const orderFormat: OrderFormatInterface = {
    user_id: auth.me.user.userId,
    payment_method_id,
    total_product_count: cart.menu.length,
    total_item_count: totalPax,
    flag_paid: false,
    total_order: totalPrice,
    total_order_point: Math.ceil(totalPrice / 1000),
    grand_total: totalPrice,
    grand_total_rounded: totalPrice,
    grand_total_point: Math.ceil(totalPrice / 1000),
    grand_total_rounded_point: Math.ceil(totalPrice / 1000),
    // TODO: discount dijadiin ada dong please
    total_discount: 0,
    total_discount_point: 0,
    total_tax: 0,
    total_tax_point: 0,
    service_type: 'DINE-IN',
    payment_channel_code: paymentMethod.pg_code,
    payment_channel_name: paymentMethod.pg_name,
    flag_dine_in: true,
    pickup_type,
    outlet_id: outlet.selected_outlet.id,
    cart: formattedCart,
    delivery_address_id: '',
    delivery_time_id: '',
    delivery_fee: 0,
    delivery_fee_point: 0,
    order_note: undefined
  };

  return orderFormat;
};
