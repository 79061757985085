import React, { Component, Fragment } from 'react';
import './Checkout.less';
import { Typography, Row, Col, Button } from 'antd';
import CartItem from 'Partials/CartItem';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as paymentAction from 'Store/modules/payment';
import * as cartAction from 'Store/modules/cart';
import Midtrans from 'Helper/midtrans';
import Navbar from 'Layout/Navbar';
import BottomBarCheckout from 'Partials/BottomBarCheckout';
const { Title } = Typography;

class Checkout extends Component {
  state = {
    cartList: [],
    selectedPayment: null,
    selectedPurchase: null
  };

  componentDidMount = async () => {
    // TODO: ESPAY ANTER KE HALAMAN ORDER-DETAIL
    if (this.props.cart.menu.length == 0) {
      this.props.history.push('/order');
    } else {
      await this.setState({
        cartList: this.props.cart.menu
      });

      this.props.getPaymentMethods(this.props.auth.me.token);
    }
  };

  componentDidUpdate = prev => {
    if (prev.order.loading !== this.props.order.loading) {
      if (
        !this.props.order.error &&
        !this.props.order.loading &&
        this.props.order.order &&
        !this.props.order.order.error
      ) {
        const { snap_token } = this.props.order.order.order;
        const { order_number } = this.props.order.order;
        const { token } = this.props.auth.me;
        const { history, clearCart } = this.props;

        const paymentCode = this.props.order.order.order.payment_channel_code;

        this.props.clearCart();

        if (paymentCode == '8') {
          this.props.history.push('/espay-gateway');
        } else if (paymentCode == '5') {
          Midtrans.pay(snap_token, order_number, token, history, clearCart);
        } else if (paymentCode == '1') {
          this.props.history.push('/thank-you-offline');
        } else if (paymentCode == '0') {
          this.props.history.push('/thank-you');
        }
      }
    }
  };

  addMoreButton = () => {
    if (this.props.lastLocation) {
      let pathname = this.props.lastLocation.pathname.split('/')[1];
      if (pathname === 'categories') {
        this.props.history.push(this.props.lastLocation.pathname);
      } else {
        this.props.history.push('/categories/boxes');
      }
    } else {
      this.props.history.push('/categories/boxes');
    }
  };

  render() {
    return (
      <Fragment>
        <Navbar history={this.props.history} />
        <div className="cart-banner">
          <Row type="flex" justify="space-between">
            <Col>
              <Title level={4}>CONFIRM ORDER</Title>
            </Col>
            <Col>
              <Button
                type="primary"
                icon="plus"
                size="small"
                onClick={this.addMoreButton}
              >
                ADD MORE
              </Button>
            </Col>
          </Row>
        </div>
        {this.state.cartList.length > 0
          ? this.state.cartList.map((cartItem, index) => {
              return (
                <Fragment key={index}>
                  <div
                    style={{
                      backgroundColor: '#F6F6F6',
                      height: '24px'
                    }}
                  />
                  <CartItem
                    editable={false}
                    cartData={cartItem}
                    key={index}
                    cartItemIndex={index}
                  />
                </Fragment>
              );
            })
          : null}
        <BottomBarCheckout history={this.props.history} />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    cart: state.cart,
    payment: state.payment,
    order: state.order
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...paymentAction, ...cartAction }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);
