const ADD_TO_CART = 'cart/ADD_TO_CART';
const CLEAR_CART = 'cart/CLEAR_CART';

const initialState = {
  menu: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        menu: action.data.item
      };
    case CLEAR_CART:
      return {
        menu: []
      };
    default:
      return state;
  }
}

// Action Creators
export function addToCart(item) {
  return {
    type: ADD_TO_CART,
    data: {
      item
    }
  };
}

export function clearCart() {
  return {
    type: CLEAR_CART
  };
}
