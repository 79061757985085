import React, { Fragment, lazy, Suspense, useState, useEffect } from 'react';
import './Login.less';
import { bindActionCreators } from 'redux';
import { Divider, Typography, Input, Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import * as faqAction from 'Store/modules/faq';
import './Account.less';

const Navbar = lazy(() => import('Layout/Navbar'));
const BottomBar = lazy(() => import('Partials/BottomBar'));
const { Text, Title } = Typography;
const { TextArea } = Input;

const Account = props => {
  useEffect(() => {
    props.getAllFaq();
  }, []);

  const { innerWidth: width, innerHeight: height } = window;

  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  const changeSubject = e => {
    setSubject(e.target.value);
  };

  const changeBody = e => {
    setBody(e.target.value);
  };

  const submitContact = () => {
    // TODO: submit contact and send the message
  };

  return (
    <Fragment>
      <Suspense fallback={<div>Loading Header</div>}>
        <Navbar />
      </Suspense>

      <div
        style={{
          textAlign: 'center',
          height: height - 140
        }}
      >
        <div style={{ padding: 14 }}>
          <Row>
            <Col style={{ marginBottom: 14 }}>
              <Title level={3} style={{ margin: 0 }}>
                Contact us below
              </Title>
              <Text>Reply and history can be seen below</Text>
            </Col>
            <Col span={24} style={{ marginBottom: 14 }}>
              <Input
                value={subject}
                placeholder="Subject"
                allowClear
                onChange={changeSubject}
              />
            </Col>
            <Col span={24} style={{ marginBottom: 14 }}>
              <TextArea
                value={body}
                placeholder="Your question"
                autosize={{ minRows: 4, maxRows: 8 }}
                onChange={changeBody}
              />
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                block
                disabled={!subject && !body}
                onClick={submitContact}
              >
                Submit
              </Button>
            </Col>
            <Col span={24} style={{ marginTop: 32, marginBottom: 32 }}>
              <Divider orientation="left">Reply & History</Divider>
            </Col>
          </Row>
        </div>
      </div>
      <Suspense fallback={<div>Loading Header</div>}>
        <BottomBar history={props.history} />
      </Suspense>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    faq: state.faq
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...faqAction }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
