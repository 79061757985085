import React, { Fragment, useState, useEffect } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';

const BannerHome = props => {
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    const bannerCond = !props.banner.bannerLoading && !props.banner.bannerError;
    if (bannerCond) {
      setBanner(props.banner.banner);
    }
  }, [props.banner]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToScroll: 1,
    className: 'banner-slider-home'
  };
  return (
    <Slider {...settings}>
      {banner &&
        banner.map((item, index) => {
          return (
            <img
              key={index}
              className="banner-image"
              alt="banner"
              src={`https://assets.dailybox.id/${item.slide_show_image_url}`}
            />
          );
        })}
    </Slider>
  );
};

function stateProps(state) {
  return {
    banner: state.banner
  };
}

export default connect(stateProps)(BannerHome);
