import React, { useEffect, lazy, Suspense } from 'react';
const Navbar = lazy(() => import('Layout/Navbar'));
const BottomBar = lazy(() => import('Partials/BottomBar'));

const EspayRepay = props => {
  useEffect(() => {
    const orderNumber = props.location.state.order_number;

    const espayConfigData = {
      key: 'f34595fffaafce37f24933b85a5e96b1',
      paymentId: orderNumber,
      // backUrl: `https://beta.dailybox.id/thank-you/?order-id=${orderNumber}`,
      // backUrl: `https://beta.dailybox.id/thank-you`,
      backUrl: `https://dailybox.id/thank-you`,
      display: 'option'
    };
    let sgoPlusIframe = document.getElementById('sgoplus-iframe');
    sgoPlusIframe.src = window.SGOSignature.getIframeURL(espayConfigData);
    window.SGOSignature.receiveForm();
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Suspense fallback={<div>Loading Header</div>}>
        <Navbar />
      </Suspense>
      <iframe
        style={{ height: 400, width: '100%' }}
        id="sgoplus-iframe"
        src=""
        scrolling="no"
        frameBorder="0"
      />
      <Suspense fallback={<div>Loading Footer</div>}>
        <BottomBar history={props.history} />
      </Suspense>
    </div>
  );
};

export default EspayRepay;
