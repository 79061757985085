import React, { Fragment, useState, useEffect, Suspense, lazy } from 'react';
import { Button, Typography, Input, Icon, message } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from 'Store/modules/auth';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const BottomBar = lazy(() => import('Partials/BottomBar'));
const Navbar = lazy(() => import('Layout/Navbar'));

const { Title, Text } = Typography;
const { Password } = Input;

const ForgotPasswordSet = props => {
  const { innerWidth: width, innerHeight: height } = window;

  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [checkLoading, setCheckLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDescription, setErrorDescription] = useState('');

  useEffect(() => {
    setCheckLoading(true);
    axios({
      method: 'GET',
      url: 'https://user-service.dailybox.id/forgot-passwords',
      params: {
        unique_key: props.match.params.uniqueKey
      }
    })
      .then(({ data }) => {
        if (data.length == 0) {
          setError(true);
          setErrorMessage('Expired link');
          setErrorDescription(
            'Please wait for 3 hours before resetting your password again'
          );
        }
        setCheckLoading(false);
      })
      .catch(() => {
        setError(true);
        setErrorMessage('Something went wrong');
        setErrorDescription(
          'Please check the link we provided or retry the process'
        );
        setCheckLoading(false);
      });
  }, []);

  const confirmPassword = () => {
    if (password !== verifyPassword) {
      message.error('Password does not match');
    } else {
      axios({
        method: 'PUT',
        url: 'https://user-service.dailybox.id/users',
        data: {
          new_password: password,
          unique_key: props.match.params.uniqueKey,
          accessToken: 'iRMUNVGlllHqZCSCIXVT'
        },
        params: {
          // get the user id after first query
          user_id: ''
        }
      })
        .then(({ data }) => {
          console.log(data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  return (
    <Fragment>
      <Suspense fallback={<div>Loading Header</div>}>
        <Navbar />
      </Suspense>

      <div
        style={{
          overflow: 'hidden',
          padding: 14,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          textAlign: 'center',
          height: height - 140,
          marginTop: 24
        }}
      >
        {checkLoading ? (
          <Fragment>
            <Title level={3}>Loading...</Title>
            <Text>Please wait</Text>
          </Fragment>
        ) : (
          <Fragment>
            {error ? (
              <Fragment>
                <Title level={3}>{errorMessage}</Title>
                <Text>{errorDescription}</Text>
              </Fragment>
            ) : (
              <Fragment>
                <Title level={3}>Create your new password</Title>
                <Password
                  style={{ marginTop: 24 }}
                  prefix={
                    <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  placeholder="Password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  size="large"
                />

                <Password
                  style={{ marginTop: 24 }}
                  prefix={
                    <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  placeholder="Verify password"
                  value={verifyPassword}
                  onChange={e => setVerifyPassword(e.target.value)}
                  size="large"
                />

                <Button
                  onClick={confirmPassword}
                  style={{ marginTop: 24 }}
                  type="primary"
                >
                  Confirm
                </Button>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>

      <Suspense fallback={<div>Loading Footer</div>}>
        <BottomBar history={props.history} />
      </Suspense>
    </Fragment>
  );
};

const dispatchProps = dispatch => {
  return bindActionCreators(
    {
      ...authActions
    },
    dispatch
  );
};

export default connect(dispatchProps)(ForgotPasswordSet);
