/**
 * Register formatter **[sm]**
 * @param data register raw data from '/register'
 * @returns formatted object for server API hit
 */

export default (data: any): object => {
  return {
    email: data.email,
    last_name: data.lastName,
    first_name: data.firstName,
    password: data.password,
    mobile_phone: data.phone,
    gender: data.gender.toString(),
    birth_date: data.birthDate.toISOString(),
    user_type_id: 1,
    register_origin_id: 1
  };
};

/**
 * Register formatter for Google sign in **[sm]**
 * @param data register raw data from google sign in API
 * @returns formatted object for server API hit
 */

export const googleRegisterFormat = (gData: any, phone: any): object => {
  return {
    google_user_id: gData.id,
    tokenId: gData.tokenId,
    id: gData.id,
    accessToken: gData.accessToken,
    email: gData.email,
    last_name: gData.last_name,
    first_name: gData.first_name,
    mobile_phone: phone,
    user_type_id: 1,
    register_origin_id: 3,
    birth_date: null,
    password: null,
    gender: 1
  };
};
