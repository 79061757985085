import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row, Button } from 'antd';
import formatPrice from 'Helper/formatPrice';
import EditBar from 'Partials/EditBar';
import optionTotal from 'Helper/optionTotal';
import { connect } from 'react-redux';

const CartPax = props => {
  const {
    eachItem,
    addAmount,
    itemData,
    optionIndex,
    removeAmount,
    removeOption,
    editNote,
    editable
  } = props;

  const [edit, setEdit] = useState(false);
  const [optionPrice, setOptionPrice] = useState(0);

  const editClose = note => {
    editNote(itemData, optionIndex, note);
    setEdit(false);
  };

  const cartCalculator = () => {
    return props.cart.menu.reduce((total, current) => {
      return (
        total +
        current.options.reduce((subtotal, option) => {
          return subtotal + option.amount;
        }, 0)
      );
    }, 0);
  };

  useEffect(() => {
    const total = optionTotal(eachItem, itemData);
    if (optionPrice !== total) {
      setOptionPrice(total);
    }
  }, [itemData]);

  return (
    <Fragment>
      <Col span={24}>
        {editable ? (
          <Fragment>
            <p
              style={{
                color: '#CECECE',
                marginBottom: 4
              }}
            >
              <i>Notes</i>
            </p>
            <EditBar
              display={edit}
              itemData={itemData}
              editValue={eachItem.notes}
              editClose={editClose}
            />
          </Fragment>
        ) : (
          <p>
            <i>{eachItem.notes} </i>
          </p>
        )}
      </Col>
      <Col span={12}>
        <Row type="flex" gutter={16}>
          {editable ? (
            <Col>
              <Button
                type={eachItem.amount === 1 ? 'danger' : 'primary'}
                shape="circle"
                icon="minus"
                icon={eachItem.amount === 1 ? 'delete' : 'minus'}
                size="small"
                onClick={() =>
                  eachItem.amount === 1
                    ? removeOption(itemData, optionIndex)
                    : removeAmount(itemData, optionIndex)
                }
              />
            </Col>
          ) : null}
          <Col>
            {!editable ? (
              <h4>{eachItem.amount} pax</h4>
            ) : (
              <h4>{eachItem.amount}</h4>
            )}
          </Col>
          {editable ? (
            <Col>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                size="small"
                onClick={() => addAmount(itemData, optionIndex)}
                disabled={cartCalculator() >= 50 ? true : false}
              />
            </Col>
          ) : null}
        </Row>
      </Col>
      <Col
        span={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <p>
          <b>{formatPrice(optionPrice)}</b>
        </p>
      </Col>
    </Fragment>
  );
};

function stateProps(state) {
  return {
    cart: state.cart
  };
}

export default connect(stateProps)(CartPax);
