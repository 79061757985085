import React, { Fragment, useState, useEffect, Suspense, lazy } from 'react';
import { Button, Typography, Input, Icon, message } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from 'Store/modules/auth';
import axios from 'axios';

const BottomBar = lazy(() => import('Partials/BottomBar'));
const Navbar = lazy(() => import('Layout/Navbar'));

const { Title, Text } = Typography;

const ForgotPassword = props => {
  const { innerWidth: width, innerHeight: height } = window;

  const [email, setEmail] = useState('');
  const [emailLoading, setEmailLoading] = useState(false);

  useEffect(() => {
    if (!props.auth.email_exist_loading && emailLoading) {
      if (props.auth.email_exist == false) {
        message.error('Your email is not registered with DailyBox');
        setEmailLoading(false);
      } else if (props.auth.email_exist == true) {
        axios({
          method: 'POST',
          url: 'https://user-service.dailybox.id/forgot-passwords',
          data: {
            email
          }
        })
          .then(({ data }) => {
            // Move to a page to let users know to check their email
            message.success('Please check your email for recovery link');
            setEmailLoading(false);
            setEmail('');
          })
          .catch(err => {
            console.log(err);
            message.error('Something went wrong, please try again later');
            setEmailLoading(false);
          });
      }
    }
  }, [props.auth]);

  const submitRecovery = () => {
    props.checkEmail(email);
    setEmailLoading(true);
  };

  return (
    <Fragment>
      <Suspense fallback={<div>Loading Header</div>}>
        <Navbar />
      </Suspense>

      <div
        style={{
          overflow: 'auto',
          padding: 14,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          textAlign: 'center',
          height: height - 140,
          marginTop: 24
        }}
      >
        <Title level={3}>Forgot your password?</Title>
        <Text>
          Please enter your registered email address. You will receive a link to
          create a new password via email.
        </Text>
        <Input
          style={{ marginTop: 36 }}
          prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Email address"
          value={email}
          onChange={e => setEmail(e.target.value)}
          size="large"
        />

        <Button
          style={{ marginTop: 24 }}
          type="primary"
          onClick={submitRecovery}
        >
          Submit
        </Button>
      </div>

      <Suspense fallback={<div>Loading Footer</div>}>
        <BottomBar history={props.history} />
      </Suspense>
    </Fragment>
  );
};

const stateProps = state => {
  return {
    auth: state.auth
  };
};

const dispatchProps = dispatch => {
  return bindActionCreators(
    {
      ...authActions
    },
    dispatch
  );
};

export default connect(
  stateProps,
  dispatchProps
)(ForgotPassword);
