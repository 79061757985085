import React from 'react';
import { Icon } from 'antd';

/**
 * Data for register page **[sm]**
 */
export default [
  {
    title: 'Please type your email',
    type: 'email',
    prefix: <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />,
    placeholder: 'Email',
    setStateTo: 'email'
  },
  {
    title: 'Please type your password',
    type: 'password',
    prefix: <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />,
    placeholder: 'Password',
    setStateTo: 'password',
    checkValid: (text: string) =>
      text ? (text.length > 0 ? true : false) : false
  },
  {
    title: 'Please confirm your password',
    type: 'password',
    prefix: <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />,
    placeholder: 'Re-type password',
    setStateTo: 'retypePassword'
  },
  {
    title: 'Please type your phone number',
    type: 'tel',
    prefix: <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />,
    placeholder: 'Phone number',
    setStateTo: 'phone'
  },
  {
    title: 'Please type your first name',
    type: 'text',
    prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
    placeholder: 'First name',
    setStateTo: 'firstName'
  },
  {
    title: 'Please type your last name',
    type: 'text',
    prefix: <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />,
    placeholder: 'Last name',
    setStateTo: 'lastName'
  },
  {
    title: 'Please select your birth date',
    type: 'datepicker',
    placeholder: 'Birth date',
    prefix: null,
    setStateTo: 'birthDate'
  },
  {
    title: 'Please select your gender',
    type: 'radio',
    placeholder: 'Gender',
    prefix: null,
    setStateTo: 'gender',
    options: [
      {
        value: 1,
        text: 'Male'
      },
      {
        value: 2,
        text: 'Female'
      },
      {
        value: 3,
        text: 'Other'
      }
    ]
  }
];
