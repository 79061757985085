/**
 * total counter for modal **[sm]**
 * @param price number
 * @param amount number
 * @param state object, the state in your component
 * @returns total: number
 */
export default function (price: number, amount: number, state: any): number {
  let addPrice = 0;
  let currentTotal = 0;
  for (let i in state) {
    if (i !== 'amount' && i !== 'notes') {
      if (Number(state[i].addPrice)) {
        addPrice += Number(state[i].addPrice);
      }
    }
  }
  if (addPrice) {
    currentTotal = (price + addPrice) * amount;
  } else {
    currentTotal = price * amount;
  }

  return currentTotal;
}
