import React, { Component } from 'react';
import { Affix } from 'antd';
import cartTotal from 'Helper/cartTotal';
import { connect } from 'react-redux';
import formatPrice from 'Helper/formatPrice';

import './BottomBarCart.less';

class BottomBarCart extends Component {
  state = {
    totalPrice: 0
  };

  componentDidMount = async () => {
    const [totalPrice, _] = await cartTotal(this.props.cart.menu);
    if (this.state.totalPrice !== totalPrice) {
      this.setState({
        totalPrice
      });
    }
  };

  componentDidUpdate = async () => {
    const [totalPrice, _] = await cartTotal(this.props.cart.menu);
    if (this.state.totalPrice !== totalPrice) {
      this.setState({
        totalPrice
      });
    }
  };

  toCart = () => {
    this.props.history.push('/checkout');
  };

  render() {
    return (
      <div className="bottom-bar-cart">
        {/*  <Affix offsetBottom={0} className="bottom-bar-cart"> */}
        <div
          className="menu-cart-parent"
          style={{
            position: 'fixed',
            bottom: 0
          }}
        >
          <div className="menu-cart" onClick={this.toCart}>
            <div className="item-cart">
              <p>CHECKOUT</p>
            </div>
            <div className="item-cart">
              <p>{formatPrice(this.state.totalPrice)}</p>
            </div>
          </div>
        </div>
        {/*</Affix>*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart
  };
}

export default connect(mapStateToProps)(BottomBarCart);
