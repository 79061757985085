import axios from "axios";

export default {
  pay: (
    snap_token: string,
    order_number: string | number,
    token: string,
    history: any,
    clearCart: any = null
  ) => {
    // @ts-ignore
    window.snap.pay(snap_token, {
      onSuccess: (res: any) => {
        if (clearCart) {
          clearCart();
        }
        history.push({
          pathname: "/thank-you",
          state: {
            res
          }
        });
      },
      onPending: (res: any) => {
        axios({
          method: "put",
          url:
            "/api/orders/new-by-order-number/" +
            order_number +
            "?access_token=" +
            token,
          data: res,
          headers: {
            "x-access-token": "uO40t2uyPCUuFIAo",
            "x-key": "api@dailybox.id"
          }
        }).then((res: any) => {
          history.push({
            pathname: `/order-detail/${order_number}`
          });
        });
      },
      onError: (res: any) => {
        console.log("ON ERROR");
        console.log("onError", res);
        history.push({
          pathname: "/order"
        });
      },
      onClose: (res: any) => {
        history.push({
          pathname: `/order-detail/${order_number}`
        });
      }
    });
  },
  show: () => {
    // @ts-ignore
    window.snap.show();
  },
  hide: () => {
    // @ts-ignore
    window.snap.hide();
  }
};
