import React, { Component, Fragment } from 'react';
import { Modal, Row, Col, Button, Select, Input } from 'antd';
import modalItemTotal from 'Helper/modalItemTotal';
import formatPrice from 'Helper/formatPrice';
import { connect } from 'react-redux';

const { TextArea } = Input;
const { Option } = Select;

class CategoryModal extends Component {
  state = {
    amount: 1,
    notes: ''
  };

  componentDidMount = async () => {
    let optionKeys = Object.keys(this.props.itemOptions);
    for (let i in optionKeys) {
      if (
        this.props.itemOptions[optionKeys[i]] !== 'amount' ||
        this.props.itemOptions[optionKeys[i]] !== 'notes'
      ) {
        await this.setState({
          [optionKeys[i]]: this.props.itemOptions[optionKeys[i]]
        });
      }
    }
  };

  itemTotal = () => {
    return modalItemTotal(this.props.item.price, this.state.amount, this.state);
  };

  amountMinus = () => {
    this.setState({
      amount: this.state.amount - 1
    });
  };

  amountPlus = () => {
    this.setState({
      amount: this.state.amount + 1
    });
  };

  modalOptionChange = async (label, val) => {
    val = await val.split('|');
    let addPrice = Number(val[1]);
    await this.setState({
      [label]: {
        val: val[0],
        addPrice
      }
    });
  };

  modalHandleOk = () => {
    this.props.modalHandleOk({
      id: this.props.item.id,
      options: this.state,
      fullMenuData: this.props.fullItem
    });
  };

  modalHandleCancel = () => {
    this.props.modalHandleCancel();
  };

  changeNote = ({ target }) => {
    this.setState({
      notes: target.value
    });
  };

  cartCalculator = () => {
    return this.props.cart.menu.reduce((total, current) => {
      return (
        total +
        current.options.reduce((subtotal, option) => {
          return subtotal + option.amount;
        }, 0)
      );
    }, 0);
  };

  render() {
    return (
      <Fragment>
        <Modal
          title={this.props.item.name}
          style={{ top: 0 }}
          visible
          onOk={this.modalHandleOk}
          onCancel={this.modalHandleCancel}
        >
          <div style={{ width: '100%' }}>
            <h4>Amount</h4>
            <Row type="flex" justify="space-around">
              <Col>
                <Button
                  type="primary"
                  shape="circle"
                  icon="minus"
                  size="large"
                  onClick={this.amountMinus}
                  disabled={this.state.amount <= 1}
                />
              </Col>
              <Col>
                <h1>{this.state.amount}</h1>
              </Col>
              <Col>
                <Button
                  type="primary"
                  shape="circle"
                  icon="plus"
                  size="large"
                  onClick={this.amountPlus}
                  disabled={
                    this.cartCalculator() + this.state.amount >= 50
                      ? true
                      : false
                  }
                />
              </Col>
            </Row>
          </div>

          {this.props.item.options.map((item, index) => {
            return (
              <div key={item.field} style={{ paddingTop: '12px' }}>
                <h4>{item.field}</h4>
                <Select
                  defaultValue={
                    item.value.split(',')[0] +
                    '|' +
                    item.add_price.split(',')[0]
                  }
                  style={{ width: '100%' }}
                  onChange={val => this.modalOptionChange(item.field, val)}
                >
                  {item.value.split(',').map((value, index) => {
                    return (
                      <Option
                        value={value + '|' + item.add_price.split(',')[index]}
                        key={value}
                      >
                        {value}
                        {Number(item.add_price.split(',')[index]) > 0
                          ? ` + ${formatPrice(
                              item.add_price.split(',')[index]
                            )}`
                          : null}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            );
          })}
          <div style={{ width: '100%', paddingTop: '12px' }}>
            <h4>Note</h4>
            <TextArea
              id="note"
              rows={4}
              placeholder="Your requests"
              value={this.state.notes}
              onChange={this.changeNote}
            />
          </div>

          <div style={{ paddingTop: '28px' }}>
            <p>
              <b>{formatPrice(this.itemTotal(this.props.item.price))}</b>
            </p>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

function stateProps(state) {
  return {
    cart: state.cart
  };
}

export default connect(stateProps)(CategoryModal);
