import { createStore, applyMiddleware, combineReducers } from "redux";
import createLogger from "redux-logger";
import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";

import auth from "Store/modules/auth";
import outlet from "Store/modules/outlet";
import category from "Store/modules/category";
import product from "Store/modules/product";
import menu from "Store/modules/menu";
import cart from "Store/modules/cart";
import payment from "Store/modules/payment";
import order from "Store/modules/order";
import banner from "Store/modules/banner";
import recommended from "Store/modules/recommended";
import orderHistory from "Store/modules/orderHistory";
import faq from "Store/modules/faq";
import topPicks from "Store/modules/topPicks";

export const history = createBrowserHistory();

const client = axios.create({
  baseURL: "https://user-service.dailybox.id/",
  responseType: "json",
  headers: {
    "x-access-token": "uO40t2uyPCUuFIAo",
    "x-key": "api@dailybox.id"
  }
});

// TODO: configure for asset getting
// const assetClient = axios.create({
//   baseURL: 'http://assets.dailybox.id/',
//   responseType: 'blob'
// });

const persistConfig = {
  timeout: 10000,
  key: "root",
  storage,
  blacklist: ["routing"]
};

const reducer = combineReducers({
  product,
  category,
  outlet,
  auth,
  menu,
  cart,
  payment,
  order,
  banner,
  topPicks,
  recommended,
  orderHistory,
  faq,
  router: connectRouter(history)
});

const persistedReducer = persistReducer(persistConfig, reducer);
const createStoreWithMiddleware = applyMiddleware(
  axiosMiddleware(client),
  routerMiddleware(history)
  // TODO: turn off and on logger to check redux
  // createLogger
)(createStore);

const configureStore = initialState => {
  let store = createStoreWithMiddleware(persistedReducer, initialState);
  let persistor = persistStore(store);
  return { store, persistor };
};
export default configureStore;
