import React, { useState, useEffect } from 'react';
import { Modal, Typography, Button } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as outletAction from 'Store/modules/outlet';
import moment from 'moment';

const { Title, Text } = Typography;

const ModalOutlet = props => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.outlet.return_user) {
      const last_access = moment(props.outlet.last_access);
      const diff = moment().diff(last_access, 'hours');
      if (diff >= 3) {
        setVisible(true);
      }
    } else {
      setVisible(true);
    }
  }, []);

  const handleOk = () => {
    props.lastAccess();
    setVisible(false);
  };

  const handleSelect = (outlet, index) => {
    props.lastAccess();
    props.selectOutlet(index);
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      cancelButtonProps={{ backgroundColor: 'red' }}
      closable={false}
      footer={
        props.outlet.return_user
          ? [
              <Button key="submit" type="primary" onClick={handleOk}>
                Yes
              </Button>
            ]
          : null
      }
    >
      {props.outlet.return_user ? (
        <div>
          <Title level={3}>
            Are you still at {props.outlet.selected_outlet.outlet_name}?
          </Title>
          <Text>Press "Yes" below to stay, or select your new location</Text>
          {props.outlet.outlets.length
            ? props.outlet.outlets.map((outlet, index) => {
                if (props.outlet.selected_outlet.id == outlet.id) {
                } else {
                  return (
                    <Button
                      key={index}
                      style={{ marginTop: 14 }}
                      onClick={() => handleSelect(outlet, index)}
                      block
                    >
                      {outlet.outlet_name}
                    </Button>
                  );
                }
              })
            : null}
        </div>
      ) : (
        <div>
          <Title level={3}>Where are you ordering from?</Title>
          {props.outlet.outlets.length
            ? props.outlet.outlets.map((outlet, index) => {
                return (
                  <Button
                    key={index}
                    style={{ marginBottom: 14 }}
                    onClick={() => handleSelect(outlet, index)}
                    block
                  >
                    {outlet.outlet_name}
                  </Button>
                );
              })
            : null}
        </div>
      )}
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    outlet: state.outlet
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...outletAction
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalOutlet);
