import React, { Fragment, useState, useEffect, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as orderHistoryAction from 'Store/modules/orderHistory';
import { Button, Typography } from 'antd';
import Lottie from 'react-lottie';
import * as checkMarkAnimation from 'Assets/Lottie/checkmark.json';

const Navbar = lazy(() => import('Layout/Navbar'));
const BottomBar = lazy(() => import('Partials/BottomBar'));
const { Title, Text } = Typography;

const ThankYou = props => {
  const [currentOrder, setCurrentOrder] = useState(null);

  useEffect(() => {
    const { token } = props.auth.me;
    const { userId } = props.auth.me.user;
    props.getAllOrders(token, userId);
  }, []);

  useEffect(() => {
    if (!props.orderHistory.loading && !props.orderHistory.error) {
      setCurrentOrder(props.orderHistory.orderHistory[0]);
    }
  }, [props.orderHistory]);

  const { innerWidth: width, innerHeight: height } = window;
  const lottieOption = {
    loop: true,
    autoplay: true,
    animationData: checkMarkAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Fragment>
      <Suspense fallback={<div>Loading Header</div>}>
        <Navbar />
      </Suspense>

      <div
        style={{
          overflow: 'hidden',
          padding: 14,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          textAlign: 'center',
          height: height - 140
        }}
      >
        <div>
          <Title level={3}>Thank you for your purchase</Title>
          <div style={{ marginTop: 18, marginBottom: 8 }}>
            {currentOrder ? (
              <Text>
                Order <Text strong>{currentOrder.order_number}</Text>
              </Text>
            ) : null}
          </div>
          <Text>
            Your order has been placed and your payment had succeeded.
          </Text>
        </div>
        <Lottie
          options={lottieOption}
          height={width / 1.3}
          width={width / 1.3}
        />
        <Link to="/order">
          <Button type="primary" size="large" block>
            Orders
          </Button>
        </Link>
      </div>

      <Suspense fallback={<div>Loading Footer</div>}>
        <BottomBar history={props.history} />
      </Suspense>
    </Fragment>
  );
};

const stateProps = state => {
  return {
    auth: state.auth,
    orderHistory: state.orderHistory,
    cart: state.cart
  };
};

const dispatchProps = dispatch => {
  return bindActionCreators({ ...orderHistoryAction }, dispatch);
};

export default connect(
  stateProps,
  dispatchProps
)(ThankYou);
