import React, { Component, Fragment, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CartItem from 'Partials/CartItem';
import { Typography, Row, Col, Button } from 'antd';
import { withLastLocation } from 'react-router-last-location';
import * as cartAction from 'Store/modules/cart';
import './Cart.less';

import Navbar from 'Layout/Navbar';
import BottomBarOrder from 'Partials/BottomBarOrder';

import { css } from 'glamor';
const SVG = lazy(() => import('react-svg'));

const { Title } = Typography;
const { innerWidth: width, innerHeight: height } = window;

const styles = css({
  ' svg': {
    height: width - 14 * 2,
    width: width - 14 * 2
  }
});

class Cart extends Component {
  state = {
    cartList: []
  };

  componentDidMount = async () => {
    if (this.props.cart.menu.length == 0) {
      this.props.history.push('/categories/boxes');
    }

    await this.setState({
      cartList: this.props.cart.menu
    });
  };

  addMoreButton = () => {
    if (this.props.lastLocation) {
      let pathname = this.props.lastLocation.pathname.split('/')[1];
      if (pathname === 'categories') {
        this.props.history.push(this.props.lastLocation.pathname);
      } else {
        this.props.history.push('/categories/boxes');
      }
    } else {
      this.props.history.push('/categories/boxes');
    }
  };

  isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
  }

  addAmount = async (item, key) => {
    item.options[key].amount += 1;
    await this.props.addToCart(this.state.cartList);
    this.setState({
      cartList: this.props.cart.menu
    });
  };

  removeAmount = async (item, key) => {
    item.options[key].amount -= 1;
    await this.props.addToCart(this.state.cartList);
    this.setState({
      cartList: this.props.cart.menu
    });
  };

  removeOption = async (item, key) => {
    if (item.options.length === 1) {
      let currentItem = false;
      for (let i in this.state.cartList) {
        if (this.state.cartList[i].id === item.id) {
          currentItem = i;
          break;
        }
      }
      let newCartList = this.state.cartList;
      newCartList.splice(currentItem, 1);
      await this.props.addToCart(newCartList);
      this.setState({
        cartList: this.props.cart.menu
      });
    } else {
      item.options.splice(key, 1);
      await this.props.addToCart(this.state.cartList);
      this.setState({
        cartList: this.props.cart.menu
      });
    }
  };

  editNote = async (item, key, note) => {
    item.options[key].notes = note;
    await this.props.addToCart(this.state.cartList);
    this.setState({
      cartList: this.props.cart.menu
    });
  };

  render() {
    return (
      <Fragment>
        <Navbar history={this.props.history} />
        <div className="cart-banner">
          <Row type="flex" justify="space-between">
            <Col>
              <Title level={4}>CART</Title>
            </Col>
            <Col>
              <Button
                type="primary"
                icon="plus"
                size="small"
                onClick={this.addMoreButton}
              >
                ADD MORE
              </Button>
            </Col>
          </Row>
        </div>
        {this.state.cartList.length > 0 ? (
          this.state.cartList.map((cartItem, index) => {
            return (
              <Fragment key={index}>
                <div
                  style={{
                    backgroundColor: '#F6F6F6',
                    height: '24px'
                  }}
                />
                <CartItem
                  editable
                  cartData={cartItem}
                  key={index}
                  addAmount={this.addAmount}
                  removeAmount={this.removeAmount}
                  removeOption={this.removeOption}
                  editNote={this.editNote}
                  cartItemIndex={index}
                />
              </Fragment>
            );
          })
        ) : (
          <div
            style={{
              overflow: 'hidden',
              padding: 14,
              textAlign: 'center',
              height: height - 204
            }}
          >
            <h2>Your cart is empty</h2>
            <p>
              Press the <b>add more</b> button above to start adding items to
              your cart.
            </p>

            <Suspense fallback={<div>Loading coming soon image</div>}>
              <SVG
                {...styles}
                src={require('Assets/Images/Template/empty_cart.svg')}
              />
            </Suspense>
          </div>
        )}
        <div style={{ height: 84 }} />
        <BottomBarOrder history={this.props.history} />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...cartAction }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLastLocation(Cart));
