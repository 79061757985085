import React, { Component, Fragment } from 'react';
import { Affix, Button, Icon, Row, Col, Modal, Typography, Badge } from 'antd';
import cartTotal from 'Helper/cartTotal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as orderAction from 'Store/modules/order';
import formatPrice from 'Helper/formatPrice';
import makeOrderFormat from 'Helper/makeOrderFormat';
import moment from 'moment';

import Logo from 'Assets/Images/Logo/logo-gopay.png';

import './BottomBarCheckout.less';

const { Text } = Typography;

class BottomBarCheckout extends Component {
  state = {
    totalPrice: 0,
    dailycoinsInsufficient: false,
    showModal: false,
    whichModal: null,
    selectedPaymentMethod: null,
    selectedPaymentTime: 'Immediately',
    paymentTimeOptions: [
      'Immediately',
      'Pre-order I (12 PM)',
      'Pre-order II (7 PM)'
    ],
    paymentTimeDetail: [
      new Date() > moment().set({ hour: 23, minute: 59, second: 59 }),
      new Date() > moment().set({ hour: 11, minute: 0, second: 0 }),
      new Date() > moment().set({ hour: 18, minute: 0, second: 0 })
    ]
  };

  componentDidMount = async () => {
    this.dailycoinsInsufficient();
    const [totalPrice, _] = await cartTotal(this.props.cart.menu);
    if (this.state.totalPrice !== totalPrice) {
      this.setState({
        totalPrice
      });
    }
  };

  componentDidUpdate = async prevProps => {
    const [totalPrice, _] = await cartTotal(this.props.cart.menu);
    if (this.state.totalPrice !== totalPrice) {
      this.dailycoinsInsufficient();
      this.setState({
        totalPrice
      });
    }

    if (this.props.payment) {
      if (
        this.props.payment.payment.length &&
        this.state.selectedPaymentMethod === null
      ) {
        this.dailycoinsInsufficient();
        await this.setState({
          selectedPaymentMethod: this.props.payment.payment[0]
        });
      }
    }
  };

  toCart = async () => {
    const formatData = await makeOrderFormat({
      cart: this.props.cart,
      auth: this.props.auth,
      outlet: this.props.outlet,
      paymentMethod: this.state.selectedPaymentMethod,
      pickupTime: this.state.selectedPaymentTime
    });

    const token = this.props.auth.me.token;
    this.props.makeOrder(token, formatData);
  };

  showModal = which => {
    this.setState({
      showModal: true,
      whichModal: which
    });
  };

  handleOk = e => {
    this.setState({
      showModal: false
    });
  };

  handleCancel = e => {
    this.setState({
      showModal: false
    });
  };

  dailycoinsInsufficient = () => {
    this.setState({
      dailycoinsInsufficient:
        this.props.auth.me.user.user.db_point <
        Math.ceil(this.state.totalPrice / 1000)
    });
  };

  modalTitleFormat = title => {
    let titleArray = title.split('-');
    for (let i = 0; i < titleArray.length; i++) {
      titleArray[i] =
        titleArray[i].charAt(0).toUpperCase() + titleArray[i].substring(1);
    }

    return titleArray.join(' ');
  };

  render() {
    const method = this.state.selectedPaymentMethod;
    const time = this.state.selectedPaymentTime;
    return (
      <Fragment>
        <div className="bottom-bar-checkout">
          {/*<Affix offsetBottom={0} className="bottom-bar-checkout">*/}
          <div className="checkout-button-parent-divider-top" />
          <div className="checkout-button-parent">
            <div
              style={{
                borderRadius: 0,
                backgroundColor: 'white',
                border: 0,
                color: '#1d1e1c',
                height: '44px',
                textShadow: 'none',
                boxShadow: 'none',
                width: '100%',
                paddingLeft: 14,
                paddingRight: 14
              }}
              onClick={() => this.showModal('payment-method')}
            >
              <Row
                type="flex"
                justify="space-between"
                style={{
                  textAlign: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                <Col>
                  {method && method.pg_code !== '5' ? (
                    <Text style={{ marginBottom: 0, fontSize: 16.5 }}>
                      {method.pg_name}
                    </Text>
                  ) : method && method.pg_code === '5' ? (
                    <img style={{ height: '24px' }} src={Logo} alt="logo"></img>
                  ) : (
                    <img style={{ height: '24px' }} src={Logo} alt="logo"></img>
                  )}
                </Col>

                <Col>
                  <Button type="primary" icon="down" shape="circle" />
                </Col>
              </Row>
            </div>
          </div>
          <div className="checkout-button-parent-divider" />
          <div className="checkout-button-parent">
            <div
              style={{
                borderRadius: 0,
                backgroundColor: 'white',
                border: 0,
                color: '#1d1e1c',
                height: '44px',
                textShadow: 'none',
                boxShadow: 'none',
                width: '100%',
                paddingLeft: 14,
                paddingRight: 14
              }}
              onClick={() => this.showModal('payment-time')}
            >
              <Row
                type="flex"
                justify="space-between"
                style={{
                  textAlign: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                <Col>
                  <Text style={{ marginBottom: 0, fontSize: 16.5 }}>
                    {time ? time : this.state.paymentTimeOptions[0]}
                  </Text>
                </Col>
                <p>
                  {new Date() >
                    moment().set({ hour: 12, minute: 0, second: 0 })}
                </p>
                <Col>
                  <Button type="primary" icon="down" shape="circle" />
                </Col>
              </Row>
            </div>
          </div>
          <div className="checkout-button-parent-divider" />
          <div className="menu-cart-parent">
            <div className="menu-cart" onClick={this.toCart}>
              <div className="item-cart">
                <p>CONFIRM PAYMENT</p>
              </div>
              <div className="item-cart">
                <p>{formatPrice(this.state.totalPrice)}</p>
              </div>
            </div>
          </div>
          {/*</Affix>*/}
        </div>

        <Modal
          title={
            this.state.whichModal
              ? this.modalTitleFormat(this.state.whichModal)
              : null
          }
          visible={this.state.showModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          {this.state.whichModal === 'payment-method' ? (
            <Fragment>
              <Row>
                {this.props.payment.payment.map((item, index) => {
                  return (
                    <Col
                      style={!index ? null : { paddingTop: '14px' }}
                      key={index}
                    >
                      {item.pg_name != 'Dailycoins' ? (
                        <Button
                          block
                          size="large"
                          onClick={() => {
                            this.setState({
                              selectedPaymentMethod: item
                            });

                            this.handleOk();
                          }}
                        >
                          {item.pg_name}
                        </Button>
                      ) : this.state.dailycoinsInsufficient ? (
                        <Button
                          block
                          size="large"
                          onClick={() => {
                            this.setState({
                              selectedPaymentMethod: item
                            });

                            this.handleOk();
                          }}
                          disabled
                        >
                          Insufficient {item.pg_name}{' '}
                          <Badge style={{ marginLeft: 6 }} status="error" />
                        </Button>
                      ) : (
                        <Button
                          block
                          size="large"
                          onClick={() => {
                            this.setState({
                              selectedPaymentMethod: item
                            });

                            this.handleOk();
                          }}
                        >
                          {item.pg_name}
                        </Button>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </Fragment>
          ) : (
            <Fragment>
              {this.state.paymentTimeOptions.map((item, index) => {
                return (
                  <Col
                    style={!index ? null : { paddingTop: '14px' }}
                    key={index}
                  >
                    <Button
                      block
                      size="large"
                      onClick={() => {
                        this.setState({
                          selectedPaymentTime: item
                        });

                        this.handleOk();
                      }}
                      disabled={this.state.paymentTimeDetail[index]}
                    >
                      {item}
                    </Button>
                  </Col>
                );
              })}
            </Fragment>
          )}
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    payment: state.payment,
    auth: state.auth,
    outlet: state.outlet,
    menu: state.menu,
    order: state.order
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...orderAction }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BottomBarCheckout);
