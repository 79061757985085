import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import formatPrice from 'Helper/formatPrice';
import orderHistoryReorder from 'Helper/orderHistoryReorder';
import { connect } from 'react-redux';

const OrderHistoryListItem = ({
  item,
  openOrderDetail,
  index,
  outlet,
  cart,
  menu
}) => {
  return (
    <div key={index} className="order-history-item-parent">
      {/* <p className="order-number-text-style">#{item.order_number}</p> */}
      <p
        className="date-text-style"
        onClick={() => orderHistoryReorder(item, cart, outlet, menu)}
      >
        RE-ORDER
      </p>
      <div onClick={() => openOrderDetail(item)}>
        <p className="date-text-style">
          {moment(item.date_updated).format('MM/DD/YYYY, hh:mm a')}
        </p>
        <p className="outlet-text-style">{item.outlet.outlet_name}</p>
        <p className="order-price-text-style">
          {formatPrice(item.grand_total)}
        </p>
        <p className="outlet-text-style">
          Paid with {item.payment_channel_name}
        </p>
        <p
          className="outlet-text-style"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {item.orderDetails.map((orderDetailsItem, index) => {
            return `${index == 0 ? '' : ', '}${
              orderDetailsItem.menu.menu_name
            }`;
          })}
        </p>
        <div style={{ marginTop: 14 }}>
          {item.orderStatus.order_status_name == 'Completed' ? (
            <Tag color="orange">{item.orderStatus.order_status_name}</Tag>
          ) : (
            <Tag>{item.orderStatus.order_status_name}</Tag>
          )}
          {!item.flag_paid ? <Tag>Unpaid</Tag> : <Tag color="orange">Paid</Tag>}
        </div>
      </div>
    </div>
  );
};

const stateProps = state => {
  return {
    outlet: state.outlet,
    cart: state.cart,
    menu: state.menu
  };
};

export default connect(stateProps)(OrderHistoryListItem);
