// Actions
const GET_CATEGORIES = 'category/GET-CATEGORIES';
const GET_CATEGORY = 'category/GET-CATEGORY';
const GET_CATEGORIES_BY_OUTLET = 'category/GET-CATEGORIES-BY-OUTLET';

const initialState = {
  categories_by_outlet: []
}
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CATEGORIES:
      return state;
    case GET_CATEGORY:
      return state;
    case GET_CATEGORIES_BY_OUTLET:
      return {
        ...state,
        categories_by_outlet: []
      };
    case GET_CATEGORIES_BY_OUTLET + '_SUCCESS':
      return {
        ...state,
        categories_by_outlet: action.payload.data.values
      };
    case GET_CATEGORIES_BY_OUTLET + '_FAIL':
      return state;
    default: return state;
  }
}

// Action Creators
export function getCategories() {
  return { 
    type: GET_CATEGORIES
  };
}

export function getCategory() {
  return { type: GET_CATEGORY };
}

export function getCategoriesByOutlet(outlet_id) {
  return { 
    type: GET_CATEGORIES_BY_OUTLET,
    payload: {
      request: {
        method: 'GET',
        url: '/categories?outlet_id=' + outlet_id
      }
    }
  };
}
