import React, { Fragment, lazy, Suspense, useState, useEffect } from 'react';
import './Login.less';
import { bindActionCreators } from 'redux';
import { Avatar, Divider, Typography, Collapse } from 'antd';
import { connect } from 'react-redux';
import * as faqAction from 'Store/modules/faq';
import './Account.less';

const Navbar = lazy(() => import('Layout/Navbar'));
const BottomBar = lazy(() => import('Partials/BottomBar'));
const { Text, Title } = Typography;
const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const Account = props => {
  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    props.getAllFaq();
  }, []);

  return (
    <Fragment>
      <Suspense fallback={<div>Loading Header</div>}>
        <Navbar />
      </Suspense>

      <div
        style={{
          padding: 14,
          textAlign: 'left',
          height: height - 140
        }}
      >
        <Title level={2}>FAQ</Title>
        <Text>Pertanyaan umum mengenai Dailybox.</Text>
        <div style={{ height: 24 }} />
        <Collapse defaultActiveKey={['0']}>
          {props.faq &&
            props.faq.faq.length &&
            props.faq.faq.map((item, index) => {
              return (
                <Panel header={item.question} key={index}>
                  <Text>{item.answer}</Text>
                </Panel>
              );
            })}
        </Collapse>
      </div>

      <Suspense fallback={<div>Loading Header</div>}>
        <BottomBar history={props.history} />
      </Suspense>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    faq: state.faq
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...faqAction }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
