import React, { Fragment, Component, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import './Login.less';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  Carousel,
  Input,
  Typography,
  Progress,
  Row,
  Col,
  Radio,
  DatePicker,
  message
} from 'antd';
import NavbarMini from 'Layout/NavbarMini';
import { bindActionCreators } from 'redux';
import './Login.less';
import './Register.less';
import * as authAction from 'Store/modules/auth';
import todo from 'Helper/registerList';
import numberCheck from 'Helper/numberCheck';
import registerFormat from 'Helper/registerFormat';
import axios from 'axios';
import DatePickerMobile from 'react-mobile-datepicker';

const { Text, Title } = Typography;

class Register extends Component {
  state = {
    registerType: null,
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
    retypePassword: '',
    birthDate: new Date(),
    datePickerOpen: false,
    gender: 1,
    slide: 0,
    todo,
    alreadySelectedBirthDate: false
  };

  nextSlide = item => {
    if (this.state.slide == todo.length - 1) {
      const formatRegisterData = registerFormat(this.state);
      this.props.register(formatRegisterData);
    } else {
      if (item.setStateTo == 'email') {
        axios({
          method: 'GET',
          url: `https://user-service.psudocode.now.sh/api/isEmailExist?email=${
            this.state[item.setStateTo]
          }`
        })
          .then(({ data }) => {
            if (data.users.length) {
              message.error('Email already registered.');
            } else {
              this.slider.next();
            }
          })
          .catch(err => {
            console.log('errors out');
          });
      } else {
        this.slider.next();
      }
    }
  };

  componentDidMount = () => {
    if (this.props.location.state) {
      if (this.props.location.state.registerType) {
        if (this.props.location.state.registerType == 'google-register') {
          this.setState({
            registerType: 'google-register',
            registerData: this.props.location.state.registerData,
            slide: 4
          });
        }
      }
    }
  };

  componentDidUpdate = () => {
    const { registerLoading, registerError, registerSuccess } = this.props.auth;
    if (!registerLoading && !registerError && registerSuccess) {
      this.props.history.push('/thank-you-register');
    }
  };

  prevSlide = () => {
    this.slider.prev();
  };

  beforeSlideChange = (current, next) => {
    this.setState({
      slide: next
    });
  };

  inputChange = (text, to) => {
    if (to == 'phone') {
      if (numberCheck(text.target.value)) {
        this.setState({
          [to]: text.target.value
        });
      }
    } else if (to == 'firstName' || to == 'lastName') {
      if (text.target.value[0]) {
        if (Object.keys(text.target.value[0].includes('toUpperCase'))) {
          this.setState({
            [to]:
              text.target.value[0].toUpperCase() + text.target.value.slice(1)
          });
        }
      } else {
        this.setState({
          [to]: text.target.value
        });
      }
    } else {
      this.setState({
        [to]: text.target.value
      });
    }
  };

  checkDisabled = item => {
    if (item.setStateTo == 'retypePassword') {
      return this.state.retypePassword == this.state.password;
    } else if (item.setStateTo == 'password') {
      return this.state.password.length >= 6;
    } else if (item.setStateTo == 'email') {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(this.state.email);
    } else if (item.setStateTo == 'phone') {
      return this.state.phone.length >= 7;
    } else if (item.setStateTo == 'lastName') {
      return this.state.lastName.length > 0;
    } else if (item.setStateTo == 'firstName') {
      return this.state.firstName.length > 0;
    } else if (item.setStateTo == 'gender') {
      return true;
    } else if (item.setStateTo == 'birthDate') {
      if (
        moment()
          .add(-1, 'years')
          .toDate() > moment(this.state.birthDate).toDate()
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  dateInputChange = selectDate => {
    const convertSelectDate = selectDate.toDate().toISOString();
    this.setState({
      birthDate: convertSelectDate
    });
  };

  render = () => {
    const percent = Number(
      ((this.state.slide / this.state.todo.length) * 100).toFixed(0)
    );
    return (
      <div className="register-page">
        <NavbarMini outletSelection={false} />
        <div style={{ marginTop: 21, textAlign: 'center' }}>
          <Progress type="circle" percent={percent} strokeColor="#f28e1f" />
        </div>
        <Carousel
          ref={slider => (this.slider = slider)}
          swipe={false}
          infinite={false}
          beforeChange={this.beforeSlideChange}
          dots={false}
        >
          {this.state.todo.map((item, index) => {
            return (
              <div key={index} className="register-form">
                <Title level={3}>{item.placeholder}</Title>
                <Text>{item.title}</Text>
                <div style={{ marginTop: 21 }} />
                <div key={index}>
                  {item.type == 'radio' ? (
                    <Radio.Group
                      value={this.state.gender}
                      onChange={value =>
                        this.inputChange(value, item.setStateTo)
                      }
                    >
                      {item.options.map(option => {
                        return (
                          <div style={{ marginBottom: 21 }}>
                            <Radio value={option.value}>{option.text}</Radio>
                            <br />
                          </div>
                        );
                      })}
                    </Radio.Group>
                  ) : item.type == 'datepicker' ? (
                    <Fragment>
                      <Button
                        type="primary"
                        shape="round"
                        block
                        onClick={() => this.setState({ datePickerOpen: true })}
                      >
                        {this.state.alreadySelectedBirthDate
                          ? moment(this.state.birthDate).format('YYYY-MM-DD')
                          : 'Select your birth date'}
                      </Button>
                      <DatePickerMobile
                        theme="ios"
                        key={index}
                        value={this.state.birthDate}
                        isOpen={this.state.datePickerOpen}
                        min={new Date(1940, 0, 1)}
                        max={new Date()}
                        confirmText="OK"
                        cancelText="Cancel"
                        onSelect={time =>
                          this.setState({
                            birthDate: time,
                            datePickerOpen: false,
                            alreadySelectedBirthDate: true
                          })
                        }
                        onCancel={() =>
                          this.setState({ datePickerOpen: false })
                        }
                      />
                    </Fragment>
                  ) : (
                    <Input
                      size="large"
                      key={index}
                      type={item.type}
                      prefix={item.prefix}
                      placeholder={item.placeholder}
                      value={this.state[item.setStateTo]}
                      onChange={text => this.inputChange(text, item.setStateTo)}
                      allowClear
                    />
                  )}
                  <div>
                    <Row gutter={16} className="register-button-row">
                      <Col span={12}>
                        <Button
                          shape="round"
                          type="primary"
                          block
                          onClick={this.prevSlide}
                          disabled={!this.state.slide}
                        >
                          Prev
                        </Button>
                      </Col>

                      <Col span={12}>
                        <Button
                          shape="round"
                          type="primary"
                          block
                          onClick={() => this.nextSlide(item)}
                          disabled={!this.checkDisabled(item)}
                        >
                          {this.state.slide + 1 >= this.state.todo.length
                            ? 'Finish'
                            : 'Next'}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    );
  };
}

const stateProps = state => {
  return {
    auth: state.auth
  };
};

const dispatchProps = dispatch => {
  return bindActionCreators({ ...authAction }, dispatch);
};

export default connect(
  stateProps,
  dispatchProps
)(Register);
