import React, { Component } from 'react';
import { Typography } from 'antd';
import './ArticlesHome.less';
import BannerSquare from 'Assets/Images/ArticleImage/chejuna.jpg';

const { Text, Title } = Typography;

class ArticleHome extends Component {
  render() {
    return (
      <div className="articles-home">
        <div className="article">
          <img
            className="image"
            src={BannerSquare}
            alt="article"
            style={{ marginTop: 14, marginBottom: 24 }}
          />
          <Text
            strong
            style={{
              fontSize: 15
            }}
          >
            Grand Launching Dailybox X Chef Juna!
          </Text>

          <div className="content" style={{ marginTop: 12 }}>
            <Text>
              Chef Juna Rorimpandey dalam kariernya baru kali ini mendapat
              tantangan dari kami untuk memasak resep nusantara. Baginya
              menemukan comfort food khas nusantara yang mudah diterima oleh
              lidah orang Indonesia bukan merupakan hal yang mudah.
            </Text>
            <div style={{ paddingTop: 18 }} />
            <Text>
              Chef Juna diminta untuk menghadirkan menu baru Dailybox yakni Ayam
              Woku khas Manado dan Udang Sambal Bawang Goreng.
            </Text>
          </div>
        </div>
      </div>
    );
  }
}

export default ArticleHome;
