import React, { Fragment, useState, useEffect, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as orderHistoryAction from 'Store/modules/orderHistory';
import { Button, Typography } from 'antd';
import Lottie from 'react-lottie';
import * as checkMarkAnimation from 'Assets/Lottie/checkmark.json';

const Navbar = lazy(() => import('Layout/Navbar'));
const BottomBar = lazy(() => import('Partials/BottomBar'));
const { Title, Text } = Typography;

const ThankYouRegister = props => {
  const { innerWidth: width, innerHeight: height } = window;
  const lottieOption = {
    loop: true,
    autoplay: true,
    animationData: checkMarkAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Fragment>
      <Suspense fallback={<div>Loading Header</div>}>
        <Navbar />
      </Suspense>

      <div
        style={{
          overflow: 'hidden',
          padding: 14,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          textAlign: 'center',
          height: height - 140
        }}
      >
        <div>
          <Title level={3}>Thank you for registering!</Title>
          <div style={{ paddingTop: 14, paddingBottom: 14 }}></div>
          <Text>Please check your email for confirmation.</Text>
        </div>
        <Lottie
          options={lottieOption}
          height={width / 1.3}
          width={width / 1.3}
        />

        <Link to="/login">
          <Button type="primary" size="large" block>
            Login
          </Button>
        </Link>
      </div>

      <Suspense fallback={<div>Loading Footer</div>}>
        <BottomBar history={props.history} />
      </Suspense>
    </Fragment>
  );
};

const mapProps = state => {
  return {
    auth: state.auth,
    orderHistory: state.orderHistory,
    cart: state.cart
  };
};

export default connect(mapProps)(ThankYouRegister);
