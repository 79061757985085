/**
 * Removal of payment gateway post API hit **[sm]**
 * @param data API result from server hit post query
 * @returns
 */
export default (data: any): any[] => {
  let newPaymentList = [];
  console.log(data);
  for (let i in data) {
    if (data[i].pg_code != "1" && data[i].pg_code != "5") {
      newPaymentList.push(data[i]);
    }
  }
  newPaymentList.push({
    pg_code: "8",
    pg_desc: "OVO and other payment methods",
    pg_name: "OVO and more"
  });

  return newPaymentList;
};
