/**
 * price (number) to string **[sm]**
 * @param price the price in number
 * @returns string of formatted price in IDR
 */
export default (price: number): string => {
  return Intl.NumberFormat('ID', { style: 'currency', currency: 'IDR' })
    .format(price)
    .split(',')[0];
};
