import React, { useEffect, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
const Navbar = lazy(() => import('Layout/Navbar'));
const BottomBar = lazy(() => import('Partials/BottomBar'));

// TODO: ESPAY ANTER KE HALAMAN ORDER-DETAIL
const Espay = props => {
  useEffect(() => {
    const orderNumber = props.order.order.order.order_number;
    console.log('ORDERNUMBER =>', orderNumber);
    if (!props.order.order.order.order_number) {
      props.history.push('/order');
    }

    const espayConfigData = {
      key: 'f34595fffaafce37f24933b85a5e96b1',
      paymentId: orderNumber,
      // backUrl: `https://beta.dailybox.id/thank-you`,
      backUrl: `https://dailybox.id/thank-you`,
      display: 'option'
    };
    let sgoPlusIframe = document.getElementById('sgoplus-iframe');
    sgoPlusIframe.src = window.SGOSignature.getIframeURL(espayConfigData);
    // console.log(
    //   'IFRAME LINK',
    //   window.SGOSignature.getIframeURL(espayConfigData)
    // );
    window.SGOSignature.receiveForm();
  }, []);

  const { innerWidth: width, innerHeight: height } = window;

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Suspense fallback={<div>Loading Header</div>}>
        <Navbar />
      </Suspense>
      <iframe
        id="sgoplus-iframe"
        src=""
        frameBorder="0"
        style={{ minHeight: height, minWidth: width }}
      />
      <Suspense fallback={<div>Loading Footer</div>}>
        <BottomBar history={props.history} />
      </Suspense>
    </div>
  );
};

function stateProps(state) {
  return {
    order: state.order
  };
}

export default connect(stateProps)(Espay);
