import React, { Component } from 'react';
import { Modal, Menu, Dropdown } from 'antd';
import './Navbar.less';
import { Link } from 'react-router-dom';

import Logo from 'Assets/Images/Logo/logo.png';
import LogoSmall from 'Assets/Images/Logo/logo-round.png';

// must have redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as authAction from 'Store/modules/auth';
import * as outletAction from 'Store/modules/outlet';
import * as cartAction from 'Store/modules/cart';
import * as bannerAction from 'Store/modules/banner';
import * as recommendedAction from 'Store/modules/recommended';
import * as topPicksAction from 'Store/modules/topPicks';

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      selectedOutlet: false
    };
  }

  componentDidMount() {
    if (!this.props.outlet.outlets.length) {
      this.props.getAllOutlets();
    }
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = async e => {
    await this.props.clearCart();
    await this.selectOutlet(this.state.selectedOutlet);
    await this.setState({
      visible: false
    });

    if (this.props.history) {
      this.props.history.push('/');
    }
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  selectOutlet = outlet_idx => {
    if (this.props.cart.menu.length) {
      this.setState({
        selectedOutlet: outlet_idx
      });
      this.showModal();
    } else {
      this.props.selectOutlet(outlet_idx);
      this.props.clearCart();
      this.props.getBanner(this.props.outlet.selected_outlet.id);
    }
  };

  render() {
    const menu = (
      <Menu>
        {this.props.outlet.outlets &&
          this.props.outlet.outlets.map((x, y) => {
            return (
              <Menu.Item key={y}>
                <a onClick={() => this.selectOutlet(y)}>{x.outlet_name}</a>
              </Menu.Item>
            );
          })}
      </Menu>
    );

    return (
      <div className="navbar">
        <div className="content">
          <div className="logo-wrapper">
            <Link to="/">
              <img className="logo" src={Logo} alt="dailybox logo"></img>
            </Link>
          </div>
          <div className="logo-wrapper-phone">
            <Link to="/">
              <img
                className="logo"
                src={LogoSmall}
                alt="dailybox mini logo"
              ></img>
            </Link>
          </div>
          <div className="outlet-selection">
            {this.props.outletSelection !== false && (
              <div className="action-wrapper">
                <div className="label">Selected Outlet</div>
                <Dropdown overlay={menu} trigger={['click']}>
                  <div className="selected">
                    {this.props.outlet.selected_outlet.outlet_name}
                  </div>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
        <Modal
          title="Change Outlet"
          style={{ top: 20 }}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <h4>Are you sure?</h4>
          <p>Cart will have to reset if you change outlet location.</p>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    outlet: state.outlet,
    cart: state.cart
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...authAction,
      ...outletAction,
      ...cartAction,
      ...bannerAction,
      ...recommendedAction,
      ...topPicksAction
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
