import React, { useState, useEffect } from 'react';
import './MenuItem.less';
import { Row, Col, Input, Button } from 'antd';
const { TextArea } = Input;

export default props => {
  const [editValue, setEditValue] = useState('');
  const [initialValue, setInitialValue] = useState(props.editValue);
  const [sameText, setSameText] = useState(true);

  useEffect(() => {
    setEditValue(props.editValue);
  }, []);

  const onChange = event => {
    setEditValue(event.target.value);
    if (event.target.value !== initialValue) {
      setSameText(false);
    } else {
      setSameText(true);
    }
  };

  const closeEdit = () => {
    setInitialValue(editValue);
    setSameText(true);
    props.editClose(editValue);
  };

  return (
    <Row
      type="flex"
      justify="space-between"
      style={{
        paddingTop: 0,
        marginBottom: 21
      }}
    >
      <Col span={21}>
        <TextArea
          placeholder="Insert note"
          id={props.itemData.id}
          rows={2}
          value={editValue}
          onChange={onChange}
        />
      </Col>
      <Col>
        <Button
          type="primary"
          disabled={sameText}
          shape="circle"
          icon="check"
          onClick={closeEdit}
        />
      </Col>
    </Row>
  );
};
