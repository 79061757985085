// Actions
const GET_ALL_OUTLETS = 'outlet/GET-ALL-OUTLETS';
const GET_OUTLET = 'outlet/GET-OUTLET';
const SELECT_OUTLET = 'outlet/SELECT-OUTLET';
const LAST_ACCESS_CHANGE = 'outlet/LAST_ACCESS_CHANGE';

const initialState = {
  outlets: [],
  selected_outlet: {},
  return_user: false,
  last_access: false
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALL_OUTLETS:
      return state;
    case GET_ALL_OUTLETS + '_SUCCESS':
      return {
        ...state,
        outlets: action.payload.data.values,
        selected_outlet: action.payload.data.values[0]
      };
    case GET_ALL_OUTLETS + '_FAIL':
      return {
        ...state
      };
    case GET_OUTLET:
      return state;
    case SELECT_OUTLET:
      return {
        ...state,
        selected_outlet: state.outlets[action.index]
      };
    case LAST_ACCESS_CHANGE:
      return {
        ...state,
        last_access: action.last_access,
        return_user: action.return_user
      };
    default:
      return state;
  }
}

// Action Creators
export function getAllOutlets() {
  return {
    type: GET_ALL_OUTLETS,
    payload: {
      request: {
        method: 'GET',
        url: '/outlets'
      }
    }
  };
}

export function getOutlet(id) {
  return {
    type: GET_OUTLET,
    payload: {
      request: {
        method: 'GET',
        url: '/outlets/' + id
      }
    }
  };
}

export function selectOutlet(index) {
  return {
    type: SELECT_OUTLET,
    index: index
  };
}

export function lastAccess() {
  return {
    type: LAST_ACCESS_CHANGE,
    return_user: true,
    last_access: new Date()
  };
}
