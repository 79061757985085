/**
 * From API order, format to order history **[sm]**
 * @param data order from over API (object)
 * @returns array of object of formatted data
 */

export default (data: any): any[] => {
  const formatArray = [];
  for (let i in data.orderDetails) {
    let duplicate = false;
    for (let j in formatArray) {
      if (formatArray[j].menu_id == data.orderDetails[i].menu_id) {
        duplicate = true;

        // TODO: check if all other keys are the same
        const currentAmount = data.orderDetails[i].qty_ordered;
        formatArray[j].qty_ordered += Number(currentAmount);
        // hotfix: assuming there are no different content between each options
        const newData = data.orderDetails[i].options;
        newData.push({
          amount: data.orderDetails[i].qty_ordered
        });
        formatArray[j].options.push(data.orderDetails[i].options);
      }
    }

    if (!duplicate) {
      const newData = data.orderDetails[i].options;
      newData.push({
        amount: data.orderDetails[i].qty_ordered
      });
      data.orderDetails[i].options = [data.orderDetails[i].options];
      formatArray.push(data.orderDetails[i]);
    }
  }
  return formatArray;
};
