/**
 * Option total helper **[sm]**
 * @param eachItem
 * @param itemData
 * @returns number for (price + addPrice) * amount
 */

export default (eachItem: any, itemData: any): number => {
  const { price } = itemData.fullMenuData;
  const { amount } = eachItem;

  let addPrice = 0;
  for (let i in eachItem) {
    if (i !== 'amount' && i !== 'notes') {
      addPrice += Number(eachItem[i].addPrice);
    }
  }

  return (price + addPrice) * amount;
};
