import React, { useEffect, useState, Fragment } from 'react';
import NavbarMini from 'Layout/NavbarMini';
import './Login.less';
import './Register.less';
import { Input, Icon, message, Typography, Button } from 'antd';
import numberCheck from 'Helper/numberCheck';
import { googleRegisterFormat } from 'Helper/registerFormat';
import { connect } from 'react-redux';
import * as authActions from 'Store/modules/auth';
import { bindActionCreators } from 'redux';

const { Text, Title } = Typography;

const GoogleRegister = props => {
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (!props.location.state) {
      props.history.push('/auth');
    } else if (!props.location.state.registerData) {
      props.history.push('/auth');
    } else {
      console.log(props.location.state.registerData);
    }
  }, []);

  useEffect(() => {
    if (props.auth.login_status == 3 && !props.auth.registerFirstTime) {
      props.history.push('/');
    }
  }, [props.auth]);

  const checkPhoneInput = e => {
    if (numberCheck(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  const submit = () => {
    const formatData = googleRegisterFormat(
      props.location.state.registerData,
      phone
    );

    console.log('Submission ongoing');
    console.log(formatData);

    props.register(formatData);
  };

  return (
    <div className="register-page">
      <NavbarMini outletSelection={false} />
      <div className="register-form">
        <Title level={3}>Phone number</Title>
        <Text>Please type your phone number</Text>
        <div style={{ marginTop: 21 }} />
        <Input
          size="large"
          type="tel"
          prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder={'Phone number'}
          value={phone}
          onChange={checkPhoneInput}
          allowClear
        />

        <div className="register-button-row">
          <Button
            shape="round"
            type="primary"
            block
            disabled={!phone.length}
            onClick={submit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

const stateProps = state => {
  return {
    auth: state.auth
  };
};

const dispatchProps = dispatch => {
  return bindActionCreators(
    {
      ...authActions
    },
    dispatch
  );
};

export default connect(
  stateProps,
  dispatchProps
)(GoogleRegister);
