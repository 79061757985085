// Actions
import removePaymentGateway from 'Helper/removePaymentGateway';
const GET_PAYMENT_METHOD = 'payment/GET_PAYMENT_METHOD';

const initialState = {
  payment: [],
  loading: 0,
  success: 0
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PAYMENT_METHOD:
      return {
        ...state,
        loading: 1
      };
    case GET_PAYMENT_METHOD + '_SUCCESS':
      return {
        ...state,
        payment: removePaymentGateway(action.payload.data.values),
        loading: 0,
        success: 1
      };
    case GET_PAYMENT_METHOD + '_FAIL':
      return {
        ...state,
        loading: 0,
        success: 0
      };
    default:
      return state;
  }
}

// Action Creators
export function getPaymentMethods(token) {
  return {
    type: GET_PAYMENT_METHOD,
    payload: {
      request: {
        method: 'GET',
        url: `/payments/?token=${token}`
      }
    }
  };
}
