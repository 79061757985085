// Actions
const GET_MENU_OUTLET_CATEGORY = 'menu/GET_MENU_OUTLET_CATEGORY';

const initialState = {
  menu: [],
  loading: 0,
  success: 0
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_MENU_OUTLET_CATEGORY:
      return {
        ...state,
        loading: 1
      };
    case GET_MENU_OUTLET_CATEGORY + '_SUCCESS':
      return {
        ...state,
        menu: action.payload.data.values,
        loading: 0,
        success: 1
      };
    case GET_MENU_OUTLET_CATEGORY + '_FAIL':
      return {
        ...state,
        loading: 0,
        success: 0
      };
    default:
      return state;
  }
}

// Action Creators
export function getMenuFilter(outletId, categoryName) {
  return {
    type: GET_MENU_OUTLET_CATEGORY,
    payload: {
      request: {
        method: 'GET',
        url: `/products/?category_name=${categoryName}&outlet_id=${outletId}`
      }
    }
  };
}
