import React, { Fragment, lazy, Suspense, useEffect, useState } from 'react';
import './Login.less';
import { bindActionCreators } from 'redux';
import { Typography, Icon } from 'antd';
import { connect } from 'react-redux';
import * as authAction from 'Store/modules/auth';
import './Account.less';
import { Link } from 'react-router-dom';
import { GoogleLogout } from 'react-google-login';

const Navbar = lazy(() => import('Layout/Navbar'));
const BottomBar = lazy(() => import('Partials/BottomBar'));
const AccountUserInfo = lazy(() => import('Partials/AccountUserInfo'));
const { Text } = Typography;

const Account = props => {
  const [loginOrigin, setLoginOrigin] = useState(1);
  useEffect(() => {
    const { token } = props.auth.me;
    const { userId } = props.auth.me.user;
    props.refetchAuth(userId, token);
    setLoginOrigin(props.auth.me.user.user.register_origin_id);
  }, []);

  const { innerWidth: width, innerHeight: height } = window;
  const { first_name, last_name, email } = props.auth.me.user.user;
  const initialName = first_name[0].toUpperCase() + last_name[0].toUpperCase();
  const capitalizeFirst = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const fullName =
    capitalizeFirst(first_name) + ' ' + capitalizeFirst(last_name);

  const resetAuth = () => {
    props.resetAuth();
    props.history.push('/');
  };

  const successGoogleLogout = () => {
    resetAuth();
  };

  const errorGoogleLogout = () => {};

  return (
    <Fragment>
      <Suspense fallback={<div>Loading Header</div>}>
        <Navbar />
      </Suspense>

      <div
        style={{
          overflow: 'hidden',
          textAlign: 'center',
          height: height - 140
        }}
      >
        <Suspense fallback={<div />}>
          <AccountUserInfo
            initialName={initialName}
            // initialName="DB"
            fullName={fullName}
            // fullName="Dailybox"
            email={email}
          />
        </Suspense>

        <div>
          <div style={{ height: 1, backgroundColor: 'rgba(0,0,0,0.2)' }} />

          <Link to="/contact">
            <div
              style={{
                display: 'flex',
                height: '42px',
                textAlign: 'left',
                paddingLeft: 14,
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <Text strong>Contact Us</Text>
            </div>
          </Link>
          <div style={{ height: 1, backgroundColor: 'rgba(0,0,0,0.2)' }} />

          <Link to="/tnc">
            <div
              style={{
                display: 'flex',
                height: '42px',
                textAlign: 'left',
                paddingLeft: 14,
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <Text strong>Terms & Conditions</Text>
            </div>
          </Link>
          <div style={{ height: 1, backgroundColor: 'rgba(0,0,0,0.2)' }} />

          <Link to="/faq">
            <div
              style={{
                display: 'flex',
                height: '42px',
                textAlign: 'left',
                paddingLeft: 14,
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <Text strong>FAQ</Text>
            </div>
          </Link>
          <div style={{ height: 1, backgroundColor: 'rgba(0,0,0,0.2)' }} />

          {loginOrigin == 3 ? (
            <GoogleLogout
              clientId="813082495336-1uhcjth34lk354uiuafdrmfi32lsr5fa.apps.googleusercontent.com"
              onLogoutSuccess={successGoogleLogout}
              onFailure={errorGoogleLogout}
              render={renderProps => (
                <div
                  disabled={renderProps.disabled}
                  onClick={renderProps.onClick && resetAuth}
                  style={{
                    display: 'flex',
                    height: '42px',
                    textAlign: 'left',
                    paddingLeft: 14,
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  <Text strong>
                    <Icon type="google" style={{ marginRight: 7.5 }} />
                    Log Out
                  </Text>
                </div>
              )}
            />
          ) : (
            <div
              onClick={resetAuth}
              style={{
                display: 'flex',
                height: '42px',
                textAlign: 'left',
                paddingLeft: 14,
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <Text strong>Log Out</Text>
            </div>
          )}

          <div style={{ height: 1, backgroundColor: 'rgba(0,0,0,0.2)' }} />
        </div>
      </div>

      <Suspense fallback={<div>Loading Header</div>}>
        <BottomBar history={props.history} />
      </Suspense>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...authAction }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
