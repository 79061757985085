import React, { Component } from 'react';
import { Button, Divider, Icon, Typography, message } from 'antd';
import './Auth.less';
import Navbar from '../Layout/Navbar';
import BannerSquare from './../Assets/Images/Logo/logo-round.png';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import * as authActions from 'Store/modules/auth';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';

const { Text } = Typography;

class Auth extends Component {
  state = {
    googleData: null
  };

  successGoogleLogin = response => {
    const googleData = {
      accessToken: response.accessToken,
      tokenId: response.tokenId,
      email: response.profileObj ? response.profileObj.email : '',
      register_origin_id: 3,
      first_name: response.profileObj ? response.profileObj.givenName : '',
      last_name: response.profileObj ? response.profileObj.familyName : '',
      id: response.googleId,
      user_type_id: 1
    };

    this.setState({ googleData });

    // TODO: ambil data gender dan tanggal ulang tahun
    // TODO: kasih penjelasan kenapa kita ngambil tanggal ulang tahun

    this.props.register(googleData);
  };

  componentDidUpdate = () => {
    const {
      registerLoading,
      registerError,
      registerSuccess,
      errorCode,
      registerFirstTime
    } = this.props.auth;

    if (registerError) {
      if (errorCode == 0.51) {
        // Wrong login method
        message.error('Wrong login method, please use our login button above');
      }
    } else if (
      !registerFirstTime &&
      registerSuccess &&
      !registerError &&
      !registerLoading
    ) {
      this.props.history.push('/');
    } else if (registerFirstTime && !registerError && !registerLoading) {
      // First time register
      this.props.history.push({
        pathname: '/google-register',
        state: {
          registerData: this.state.googleData
        }
      });
    }
  };

  failGoogleLogin = res => {
    // kirim report
    if (res.error == 'idpiframe_initialization_failed') {
    } else {
      message.error('Google sign in error. Please try again later');
    }
  };

  render() {
    return (
      <div className="auth-page">
        <Navbar outletSelection={false} />
        <div className="auth-page-content">
          <img
            className="auth-page-content-image"
            src={BannerSquare}
            style={{
              backgroundColor: '#1d1e1c',
              width: 120,
              borderRadius: 120 / 2
            }}
            alt="banner"
          />
          <div className="auth-page-content-wording-wrapper">
            <div className="auth-page-content-wording-wrapper-content">
              <h2>Welcome to Dailybox!</h2>
              <Text>
                By using our application, you get to experience a wide-range of
                features. Join us by pressing <strong>Register</strong> or{' '}
                <strong>Login</strong> if you already have an account.
              </Text>
              <div
                className="auth-page-content-wording-wrapper-content-action-button"
                style={{
                  marginTop: 36
                }}
              >
                <Button
                  shape="round"
                  size={'large'}
                  type="primary"
                  onClick={() => {
                    this.props.history.push('/login');
                  }}
                >
                  Login
                </Button>
                <Button
                  shape="round"
                  size={'large'}
                  type="primary"
                  onClick={() => {
                    this.props.history.push('/register');
                  }}
                >
                  Register
                </Button>
              </div>
              <div style={{ textAlign: 'center', marginTop: 21 }}>
                <Button
                  type="link"
                  onClick={() => this.props.history.push('/forgot-password')}
                >
                  Forgot your password?
                </Button>
              </div>
              <Divider>OR</Divider>
              <div className="auth-page-content-wording-wrapper-content-action-button">
                <GoogleLogin
                  clientId="813082495336-1uhcjth34lk354uiuafdrmfi32lsr5fa.apps.googleusercontent.com"
                  render={renderProps => (
                    <Button
                      disabled={renderProps.disabled}
                      onClick={renderProps.onClick}
                      shape="round"
                      size={'large'}
                      type="primary"
                    >
                      <Icon type="google" />
                      Google
                    </Button>
                  )}
                  onSuccess={this.successGoogleLogin}
                  onFailure={this.failGoogleLogin}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const stateProps = state => {
  return {
    auth: state.auth
  };
};

const dispatchProps = dispatch => {
  return bindActionCreators(
    {
      ...authActions
    },
    dispatch
  );
};

export default connect(
  stateProps,
  dispatchProps
)(Auth);
