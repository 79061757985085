// Actions
const GET_TOP_PICKS = 'topPicks/GET_TOP_PICKS';

const initialState = {
  topPicks: [],
  topPicksLoading: false,
  topPicksError: false
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TOP_PICKS:
      return {
        ...state,
        topPicksLoading: true,
        topPicksError: false
      };
    case GET_TOP_PICKS + '_SUCCESS':
      return {
        ...state,
        topPicksLoading: false,
        topPicksError: false,
        topPicks: action.payload.data.values
      };
    case GET_TOP_PICKS + '_FAIL':
      return {
        ...state,
        topPicksLoading: false,
        topPicksError: true
      };
    default:
      return state;
  }
}

export function getTopPicks(outlet_id) {
  return {
    type: GET_TOP_PICKS,
    payload: {
      request: {
        method: 'GET',
        url: `/products/?${outlet_id}`
      }
    }
  };
}
