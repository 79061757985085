import React, { Fragment, lazy, Component, Suspense } from 'react';
import './CategorySliderHomeVanilla.less';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as categoryAction from 'Store/modules/category';
import * as outletAction from 'Store/modules/outlet';

const CategorySliderItem = lazy(() => import('Partials/CategorySliderItem'));

class CategorySliderHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount = () => {
    this.setState({
      loading: true
    });
    const outletId = this.props.outlet.selected_outlet.id;
    this.props.getCategoriesByOutlet(outletId);
  };

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.outlet.selected_outlet.id !==
      this.props.outlet.selected_outlet.id
    ) {
      this.props.getCategoriesByOutlet(nextProps.outlet.selected_outlet.id);
    }
  };

  componentDidUpdate = prevProps => {
    const differentCategory =
      this.props.category.categories_by_outlet.length !==
      prevProps.category.categories_by_outlet.length;
    if (
      this.props.category.categories_by_outlet.length > 0 &&
      differentCategory
    ) {
      this.setState({
        loading: false
      });
    }
  };

  render() {
    return (
      <Fragment>
        <div className="category-slider-home-vanilla-parent">
          {this.props.category.categories_by_outlet &&
            this.props.category.categories_by_outlet.map((x, i) => {
              return (
                <Suspense fallback={<div />} key={i}>
                  <CategorySliderItem
                    clearSearch={this.props.clearSearch}
                    categoryPath={this.props.categoryPath}
                    category_name={x.category_name}
                  />
                </Suspense>
              );
            })}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    category: state.category,
    outlet: state.outlet
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...categoryAction, ...outletAction }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategorySliderHome);
